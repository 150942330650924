export default {
  numberFormats: {
    currency: {
      style: 'currency',
      currency: 'AUD',
    },
  },
  messages: {
    percent_offer_desc: 'E.g. 10% off all pasta dishes or 15% off total order.',
    total_order_desc: 'E.g. 10% off your order when you spend $20.',
    value_off_desc: 'E.g. $2 off burger combos or $5 off orders over $20.',
    reach_desc: 'E.g. Get a free dessert when you spend $20.',
    redbull_label: 'Boost your orders on Menulog',
    redbull_title: 'Be part of the new RedBull offer',
    redbull_description:
      'Red Bull is offering a new promo to help increase your orders on Menulog. The customers simply need to order any Red bull and will get 20% off their entire order. If you are keen to participate press the opt-in bottom below to see the next steps.<br /><br /> By Clicking Opt In you confirm that you have read the Learn More section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.',
    redbull_success_toast: "You've registered for RedBull offer",
    redbull_opted_in_title: 'Boost your orders with Red Bull',
    redbull_opted_in_description:
      'You can see all the details <a class="font-bold text-blue-100" href="{link}">here</a>.',
  },
  dateTimeFormats: {},
}
