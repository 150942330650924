export const OfferTypes = {
  Percent: 'Percent',
  FreeItem: 'FreeItem',
  ItemLevelDiscount: 'ItemLevelDiscount',
  Bogof: 'Bogof',
  BogofMixMatch: 'BogofMixMatch',
}

export const ConsumerSegment = {
  All: 'All',
  NewToRestaurant: 'NewToRestaurant',
}

export const TimeDurationType = {
  Day: 'Day',
  Hours: 'Hours',
}

export const EndDateType = {
  Never: 'Never',
  Selected: 'Selected',
}

export const StartDateType = {
  Immediately: 'Immediately',
  Date: 'Date',
}

export const campaignStatus = {
  Subscribed: 'Subscribed',
  Unsubscribed: 'Unsubscribed',
}
