<template>
  <div class="flex items-stretch flex-wrap w-full mt-4">
    <img
      v-if="showCampaignText"
      class="rounded-tl-radius-c rounded-tr-radius-c w-full"
      :src="campaignBanner"
      :alt="$t(campaignId + '_alt')"
      :data-test-id="campaignId.toLowerCase() + '-banner'"
    />
    <slot />
  </div>
</template>

<script>
import { CAMPAIGN_CONFIGURATIONS } from '../helpers/campaignConfigurations'
import { CAMPAIGN_DAYS } from '../constants'
import { campaignStatus } from '../store/helpers'

export default {
  data() {
    return {
      CAMPAIGN_DAYS,
      isLoading: false,
    }
  },
  props: {
    campaign: {
      type: Object,
    },
    day: {
      type: String,
      required: true,
    },
    hasOffers: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    campaignConfiguration() {
      return this.campaign && CAMPAIGN_CONFIGURATIONS[this.campaign.id]
    },
    showCampaignText() {
      return (
        this.campaignConfiguration &&
        this.CAMPAIGN_DAYS.includes(this.day) &&
        this.hasOffers
      )
    },
    campaignId() {
      return this.campaign?.id.toLowerCase() || ''
    },
    campaignBanner() {
      return (
        this.campaignConfiguration &&
        require('../assets/campaigns/' + this.campaignConfiguration.banner)
      )
    },
    infoText() {
      const amount =
        this.campaign?.offerTemplate?.percent?.discountPercent || ''
      const campaignDay = this.day
      const anyOffers =
        this.hasOffers || this.campaign.status === campaignStatus.Subscribed
      return anyOffers
        ? this.$t(this.campaignId + '_offer', {
            percent: amount,
            day: campaignDay,
          })
        : this.$t(this.campaignId + '_info')
    },
    campaignTermsAndConditionsLink() {
      return this.campaignConfiguration.termsAndConditionsLink
    },
  },
}
</script>
