export default {
  numberFormats: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
  messages: {
    total_order_desc: 'E.g. 10% off your order when you spend €20.',
    value_off_desc: 'E.g. €2 off burger combos or $5 off orders over €20.',
    reach_desc: 'E.g. Get a free dessert when you spend €20.',
    cheekytuesday_text:
      'Give your customers 20% off orders over €20 every Tuesday. We’ll get your restaurant in front of new customers by featuring it in our email and online marketing.',
    opt_in: 'Opt in',
    cheekytuesday_info:
      'To run a % off offer on Tuesdays, restaurants must opt-in to Cheeky Tuesdays',
    cheekytuesday_offer:
      'This offer is part of the Cheeky {day}’s {percent}% off campaign - it will be automatically deleted at the end of the campaign.',
    cheekytuesday_toast: 'Cheeky {day} added',
    cheekytuesday_toast_deleted: 'Cheeky {day} deleted',
    cheekytuesday_toast_deleted_tonight:
      'Cheeky {day} offer will be deleted tonight',
    cheekytuesday_title: 'Cheeky {day}',
    cheeky_only_offers:
      'Cheeky Tuesdays are the only offers that can run on Tuesday, whether your restaurant takes part or not.',
    cheeky_and_sunday_offers:
      'Cheeky Tuesdays and Super Hero Sundays are the only offers that can run on Tuesday and Sunday, whether your restaurant takes part or not.',
    cheekytuesday_you_opted_info:
      '{percent}% off orders {value} or more. Every {day} from {date}.',
    cheekytuesday_alt: 'Cheeky Tuesday’s Campaign',
    rb_title: 'Free Cases of Red Bull',
    rb_label: 'Great for increasing AOV',
    rb_desc:
      'Red Bull are offering one free case of Red Bull Energy Drink for you to add to your menu FREE of charge',
    rb_success_toast:
      'Thanks for signing up - your product will be delivered in the next 10 days.',
    rb_opted_in_title: 'Your FREE cases are coming!',
    rb_opted_in_desc:
      "You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
  },
  dateTimeFormats: {},
}
