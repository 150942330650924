<template>
  <pc-modal
    v-model="isVisible"
    size="medium"
    data-test-id="offer-deletion-modal"
    escapable
  >
    <template slot="title">
      <span data-test-id="offer-deletion-modal-title">
        {{ $t('want_delete_offer') }}
      </span>
    </template>
    <template slot="content">
      <p
        data-test-id="offer-deletion-modal-content"
        v-html="$t('are_you_sure', { offer: offerTitle })"
      ></p>
      <p
        v-if="isVisibleCampaignText"
        class="campaign-text"
        v-html="$t('delete_campaign_text')"
      ></p>
      <div class="mt-4 flex justify-end">
        <pc-button
          type="ghost"
          @click="close"
          class="mr-4"
          data-test-id="cancel-offer-deletion-btn"
        >
          {{ $t('cancel') }}
        </pc-button>
        <pc-button
          :loading="isLoading"
          class="delete"
          data-test-id="confirm-offer-deletion-btn"
          @click="deleteOffer"
        >
          {{ $t('delete') }}
        </pc-button>
      </div>
    </template>
  </pc-modal>
</template>

<script>
import dayjs from 'dayjs'
import { PcModal, PcButton } from '@je-pc/ui-components'
import analytics from '../utils/analytics'
import { TOGGLE_DELETE_MODAL } from '../store/mutation.type'
import { SHOW_DELETE_MODAL } from '../store/getters.type'
import { CAMPAIGN_DAYS } from '../constants'
export default {
  data() {
    return {
      CAMPAIGN_DAYS,
      isLoading: false,
    }
  },
  components: {
    PcModal,
    PcButton,
  },
  props: {
    offerTitle: {
      type: String,
    },
    campaign: {
      type: Object,
    },
    day: {
      type: String,
    },
  },
  computed: {
    today() {
      return dayjs().format('dddd')
    },
    showModal() {
      return this.$store.getters[SHOW_DELETE_MODAL]
    },
    isVisible: {
      get() {
        return this.showModal
      },
      set(value) {
        this.toggleDeleteModal(value)
      },
    },
    isVisibleCampaignText() {
      return this.CAMPAIGN_DAYS.includes(this.today) && this.day === this.today
    },
  },
  methods: {
    deleteOffer() {
      this.isLoading = true
      this.$emit('delete', this.isVisibleCampaignText)
    },
    close() {
      analytics.DeleteOfferCancel()
      this.toggleDeleteModal(false)
    },
    toggleDeleteModal(value) {
      this.$store.commit(TOGGLE_DELETE_MODAL, value)
    },
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        this.isLoading = false
      }
    },
  },
}
</script>
