import { ApiClient } from '@rmp/api-client'

const defaultAccept = 'application/json;v=2.0'
const { smartGatewayUrl } = ApiClient.getApiLinks()

const http = new ApiClient({
  baseURL: smartGatewayUrl,
  options: {
    returnFullResponse: true,
  },
})

export const getCampaigns = () => {
  return http
    .get('/applications/partneroffers/{tenant}/{restaurantId}/campaigns', {
      headers: { Accept: defaultAccept },
    })
    .then((res) => res.data)
}

export const getFeatureToggles = () => {
  return http
    .get('applications/partneroffers/{tenant}/{restaurantId}/feature-toggles')
    .then((res) => res.data)
}

export const getOffers = (offerTypes) => {
  return http
    .get('/applications/partneroffers/{tenant}/{restaurantId}/offers', {
      headers: { Accept: defaultAccept },
      params: {
        offerTypes,
      },
      paramsSerializer: {
        indexes: null,
      },
    })
    .then((res) => res.data)
}

export const getOfferInsightsReport = () => {
  return http
    .get(
      '/reports/{tenant}/restaurants/{restaurantId}/marketing/offer-insights-last-week'
    )
    .then((res) => res.data)
}

export const optIn = (campaignId) => {
  const data = [
    {
      op: 'replace',
      path: '/status',
      value: 'Subscribed',
    },
  ]
  return http.patch(
    `/applications/partneroffers/{tenant}/{restaurantId}/campaigns/${campaignId}`,
    data,
    {
      headers: { 'Content-Type': 'application/json-patch+json;v=2.0' },
    }
  )
}

export const optOut = (campaignId) => {
  const data = [
    {
      op: 'replace',
      path: '/status',
      value: 'Unsubscribed',
    },
    {
      op: 'replace',
      path: '/statusReason',
      value: 'unsubscribeFromPartnerCentre',
    },
  ]
  return http.patch(
    `/applications/partneroffers/{tenant}/{restaurantId}/campaigns/${campaignId}`,
    data,
    {
      headers: { 'Content-Type': 'application/json-patch+json;v=2.0' },
    }
  )
}

export const deleteOffer = (id) => {
  return http.delete(
    `/applications/partneroffers/{tenant}/{restaurantId}/offers/${id}`,
    {
      headers: { Accept: defaultAccept },
    }
  )
}
