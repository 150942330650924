export const OfferSubTypes = {
  FreeItemWithItem: 'FreeItemWithItem',
  FreeItemMinSpend: 'FreeItemMinSpend',
  PercentSelectedItems: 'PercentSelectedItems',
  PercentTotalOrder: 'PercentTotalOrder',
  ValueSelectedItems: 'ValueSelectedItems',
  BogofMixMatch: 'BogofMixMatch',
}

export const OfferTypes = {
  Percent: 'Percent',
  FreeItem: 'FreeItem',
  ItemLevelDiscount: 'ItemLevelDiscount',
  Bogof: 'Bogof',
  BogofMixMatch: 'BogofMixMatch',
}

export const OfferSubTypeToOfferType = {
  [OfferSubTypes.FreeItemWithItem]: OfferTypes.FreeItem,
  [OfferSubTypes.FreeItemMinSpend]: OfferTypes.FreeItem,
  [OfferSubTypes.PercentSelectedItems]: OfferTypes.ItemLevelDiscount,
  [OfferSubTypes.ValueSelectedItems]: OfferTypes.ItemLevelDiscount,
  [OfferSubTypes.PercentTotalOrder]: OfferTypes.Percent,
  [OfferSubTypes.BogofMixMatch]: OfferTypes.BogofMixMatch,
}
