<template>
  <div class="container">
    <collapse-transition>
      <pc-notification
        v-if="notification.show"
        :title="notification.title"
        type="error"
      >
        {{ notification.message }}
      </pc-notification>
    </collapse-transition>
  </div>
</template>

<script>
import { PcNotification, CollapseTransition } from '@je-pc/ui-components'
import { NOTIFICATION } from '../store/getters.type'

export default {
  components: {
    CollapseTransition,
    PcNotification,
  },
  computed: {
    notification() {
      return this.$store.getters[NOTIFICATION]
    },
  },
}
</script>
