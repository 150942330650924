<template>
  <pc-error-boundary @on-error="handleComponentError">
    <div class="px-4 sm:px-6 pb-4 sm:pb-6">
      <error-notification />
      <pc-page-transition>
        <router-view />
      </pc-page-transition>
      <pc-toast />
    </div>
  </pc-error-boundary>
</template>

<script>
import { PcPageTransition, PcErrorBoundary } from '@je-pc/ui-components'
import ErrorNotification from './components/ErrorNotification.vue'
import logger from './services/logger'
import { GET_SETTINGS } from './store/action.types'

export default {
  components: {
    PcPageTransition,
    PcErrorBoundary,
    ErrorNotification,
  },
  async mounted() {
    await this.$store.dispatch(GET_SETTINGS)
  },
  methods: {
    handleComponentError({ error, loggerPayload }) {
      logger.error(error, loggerPayload)
    },
  },
}
</script>
