<template>
  <div class="container mt-6" :data-test-id="'offers-list-container'">
    <skeleton-loader v-if="isLoading" />
    <template v-else>
      <div class="grid grid-cols-1 lg:grid-cols-3 md:gap-6">
        <div class="lg:col-span-2 order-2 lg:order-1">
          <p
            class="flex items-center mt-4"
            v-if="isOfferInsightsEnabled"
            data-test-id="offer-insights-time-period-note"
          >
            <span class="font-bold mr-2">
              {{ $t('offer_insights_time_period') }}
            </span>
            <IconInfoCircle
              v-pc-tooltip="{
                placement: 'bottom',
                content: $t('offer_insights_time_period_note'),
                allowHTML: true,
              }"
              class="cursor-pointer"
              iconSize="m"
            />
          </p>
        </div>
        <div class="order-1 lg:order-2 mb-6 md:mb-0"></div>
      </div>
      <div
        class="mt-8"
        :id="day.toLowerCase() + '-offers-list'"
        v-for="day in daysList"
        :key="day"
        :data-test-id="day.toLowerCase() + '-offers-list'"
      >
        <span class="my-2 pc-heading-s">{{ $t(day) }}</span>
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-6 items-start"
        >
          <campaign-wrapper
            v-if="isCheekyCampaignDay(day) && !hasCheekyOffers(day)"
            :day="day"
            :campaign="getCampaignByDay(day)"
          />
          <campaign-offer-wrapper
            @opt-out="handleOptOut"
            v-for="(offer, index) in offerList[day]"
            :key="offer.offerId"
            :hasOffers="isCheekyOffer(offer.campaignId)"
            :day="day"
            :campaign="getCampaignByDay(day)"
          >
            <offer-card
              @delete="handleDeleteOffer"
              :campaign="getCampaignByDay(day)"
              :offer="offer"
              :day="day"
              :data-test-id="day.toLowerCase() + '-offer-card-' + index"
            />
          </campaign-offer-wrapper>
          <div
            v-if="!offerList[day] && !isCheekyCampaignDay(day)"
            :data-test-id="day.toLowerCase() + '-empty-offers-list'"
            class="text-center bg-white border-2 border-dashed border-strong py-2 px-4 w-full rounded-radius-b mt-4 font-bold"
          >
            {{ $t('no_active_offers') }}
          </div>
        </div>
      </div>
      <delete-modal
        :campaign="deletableCampaign"
        :offerTitle="offerTitle"
        :day="deletableOfferDay"
        @delete="removeOffer"
      />
      <opt-out-modal
        @cancel="toggleOptOutModal(false)"
        @opt-out="unsubscribeCampaign"
        :day="deletableOfferDay"
        :campaignTitle="getDeletabledDayCampaignTitle()"
      />
    </template>
  </div>
</template>

<script>
import { eventBus } from '@je-pc/utils'
import { IconInfoCircle } from '@justeattakeaway/pie-icons-vue'
import CampaignWrapper from '../components/CampaignWrapper.vue'
import CampaignOfferWrapper from '../components/CampaignOfferWrapper.vue'
import OfferCard from '../components/OfferCard.vue'
import DeleteModal from '../components/DeleteModal.vue'
import OptOutModal from '../components/OptOutModal.vue'
import SkeletonLoader from '../components/SkeletonLoader.vue'

import { GET_OFFERS, DELETE_OFFER, OPT_OUT } from '../store/action.types'
import { TOGGLE_DELETE_MODAL, SET_NOTIFICATION } from '../store/mutation.type'
import {
  ENABLED_CAMPAIGNS,
  IS_OFFER_INSIGHTS_BANNER_ENABLED,
  OFFERS_TO_DISPLAY_ON_LIST,
  SERVER_ERROR,
  ENABLED_CHEEKY_CAMPAIGNS,
} from '../store/getters.type'
import { days } from '@/helpers/dateTimeHelper'
import analytics from '../utils/analytics'
import { CAMPAIGN_CONFIGURATIONS } from '../helpers/campaignConfigurations'
import { CAMPAIGN_DAYS } from '../constants'
import logger from '../services/logger'

export default {
  title() {
    return this.$t('offers_title')
  },
  components: {
    CampaignWrapper,
    CampaignOfferWrapper,
    OfferCard,
    DeleteModal,
    OptOutModal,
    SkeletonLoader,
    IconInfoCircle,
  },
  data() {
    return {
      CAMPAIGN_DAYS,
      deletableCampaign: null,
      offerTitle: '',
      deletableOfferDay: '',
      deletableOffer: null,
      isLoading: false,
    }
  },
  computed: {
    enabledCampaigns() {
      return this.$store.getters[ENABLED_CAMPAIGNS]
    },
    serverNotification() {
      return this.$store.getters[SERVER_ERROR]
    },
    isOfferInsightsEnabled() {
      return this.$store.getters[IS_OFFER_INSIGHTS_BANNER_ENABLED]
    },
    daysList() {
      return days
    },
    offerList() {
      return this.$store.getters[OFFERS_TO_DISPLAY_ON_LIST]
    },
    enabledCheekyCampaigns() {
      return this.$store.getters[ENABLED_CHEEKY_CAMPAIGNS]
    },
  },
  async mounted() {
    await this.init()
    eventBus.on('deleteOffer', this.handleDeleteOffer)
    eventBus.on('campaignStatusChange', this.init)
  },
  methods: {
    async init() {
      this.isLoading = true
      await this.$store.dispatch(GET_OFFERS)
      this.isLoading = false
    },
    async deleteOffer(id) {
      await this.$store.dispatch(DELETE_OFFER, id)
    },
    async optOutFromCampaign(campaignId) {
      await this.$store.dispatch(OPT_OUT, campaignId)
    },
    getCampaignByDay(day) {
      return (
        this.enabledCampaigns.find(
          (c) =>
            this.CAMPAIGN_DAYS.includes(day) &&
            CAMPAIGN_CONFIGURATIONS[c.id] &&
            c.runningPeriods[0].daysOfWeek.includes(day)
        ) || null
      )
    },
    hasCheekyOffers(day) {
      return this.offerList[day]?.some((offer) =>
        this.isCheekyOffer(offer.campaignId)
      )
    },
    isCheekyOffer(campaignId) {
      return !!this.enabledCheekyCampaigns.find((c) => c.id === campaignId)
    },
    getDeletabledDayCampaignTitle() {
      const campaign = this.getCampaignByDay(this.deletableOfferDay)
      if (campaign) {
        if (campaign.id === 'cheekyCampaign') {
          return 'Cheeky'
        }
      }
      return ''
    },
    handleOptOut(campaign) {
      this.deletableCampaign = campaign
      this.deletableOfferDay = campaign.daysOfWeek[0]
      this.toggleOptOutModal(true)
    },
    handleDeleteOffer({ offer, title }) {
      analytics.DeleteOfferStart()
      this.deletableOffer = offer
      this.deletableOfferDay = offer.dayOfWeek
      this.deletableCampaign =
        this.enabledCampaigns.find(
          (c) =>
            c.id === offer.campaignId && c.type !== 'MatchingByOfferTemplate'
        ) || null
      this.toggleDeleteModal(true)
      this.offerTitle = title
    },
    isCheekyCampaignDay(day) {
      return this.enabledCheekyCampaigns.some(() =>
        this.CAMPAIGN_DAYS.includes(day)
      )
    },
    async removeOffer(isCurrentDayOffer) {
      const msg = this.$t('delete_toast', { value: this.offerTitle })

      const toastMessage = this.deletableCampaign
        ? this.getCampaignOfferDeletedMsg(
            this.deletableOffer.campaignId,
            isCurrentDayOffer
          )
        : msg
      try {
        this.deletableCampaign
          ? await this.optOutFromCampaign(this.deletableCampaign.id)
          : await this.deleteOffer(this.deletableOffer.offerId)
        this.toggleDeleteModal(false)
        this.$notify({
          type: 'success',
          message: `${toastMessage}`,
        })
        this.deletableCampaign
          ? analytics.OptOutSuccess({
              nActiveOffers: this.offersLength,
              campaign: this.deletableCampaign.id,
            })
          : analytics.DeleteOfferSuccess(this.offersLength)
      } catch (err) {
        logger.error(err, {
          deletableOffer: this.deletableOffer,
          deletableOfferDay: this.deletableOfferDay,
          deletableCampaign: this.deletableCampaign,
          offerTitle: this.offerTitle,
        })
        analytics.DeleteOfferError(err)
        this.$store.commit(SET_NOTIFICATION, this.serverNotification)
      }
    },
    toggleDeleteModal(value) {
      this.$store.commit(TOGGLE_DELETE_MODAL, value)
    },
    getCampaignOfferDeletedMsg(campaignId, isCurrentDayOffer) {
      return isCurrentDayOffer
        ? this.$t(campaignId.toLowerCase() + '_toast_deleted_tonight', {
            day: this.deletableOfferDay,
          })
        : this.$t(campaignId.toLowerCase() + '_toast_deleted', {
            day: this.deletableOfferDay,
          })
    },
    async unsubscribeCampaign() {
      const campaign = this.getCampaignByDay(this.deletableOfferDay)
      if (campaign) {
        const msg = this.$t(campaign.id.toLowerCase() + '_toast_deleted', {
          day: campaign.daysOfWeek[0],
        })
        try {
          await this.optOutFromCampaign(campaign.id)
          this.toggleOptOutModal(false)
          this.$notify({
            type: 'success',
            message: `${msg}`,
          })
          analytics.OptOutSuccess({
            nActiveOffers: this.offersLength,
            campaign: campaign.id,
          })
        } catch (err) {
          logger.error(err)
          analytics.DeleteOfferError(err)
          this.$store.commit(SET_NOTIFICATION, this.serverNotification)
        }
      }
    },
  },
}
</script>
