<template>
  <div class="flex flex-col items-stretch w-full mt-4">
    <template v-if="showCampaignText">
      <img
        class="rounded-tl-radius-c rounded-tr-radius-c w-full"
        :src="campaignBanner"
        :alt="$t(campaignId + '_alt')"
        :data-test-id="campaignId.toLowerCase() + '-banner'"
      />
      <div
        class="bg-white shadow-elevation-01 p-4 rounded-bl-radius-c rounded-br-radius-c flex flex-col items-center"
      >
        <template v-if="showCampaignPromotion">
          <p class="text-sm" :data-test-id="this.campaignId + '-text'">
            <span v-html="campaignText" />
            <a class="text-blue-100" :href="campaignTermsAndConditionsLink">
              {{ $t('tc_apply') }}
            </a>
          </p>
          <pc-button
            class="mt-4"
            :loading="isLoading"
            @click="optIn"
            :data-test-id="this.campaignId + '-opt-in-btn'"
          >
            {{ $t('opt_in') }}
          </pc-button>
          <p
            v-if="campaignAdditionalText"
            v-html="campaignAdditionalText"
            :data-test-id="this.campaignId + '-additional-txt'"
          ></p>
        </template>
        <template v-if="showCampaignOfferPlaceholder">
          <div>
            <span class="font-bold text-xl">{{ $t('you_opted') }}</span>
            <div class="text-sm">
              {{ optedInInfo }}
            </div>
          </div>
          <button
            class="absolute rounded-full top-0 right-0 p-4 focus:outline-none focus:shadow-outline-button bg-transparent border-transparent hover:bg-grey-20 active:bg-grey-25"
            @click="optOut"
            icon="trash"
            data-test-id="offer-card-opt-out-btn"
          >
            <pc-icon class="w-6 h-6" name="delete" />
          </button>
        </template>
        <pc-notification
          v-if="infoText"
          class="mt-4"
          :data-test-id="this.campaignId + '-note'"
          type="info"
        >
          {{ infoText }}
        </pc-notification>
      </div>
    </template>
  </div>
</template>

<script>
import { PcButton, PcNotification } from '@je-pc/ui-components'
import { CAMPAIGN_CONFIGURATIONS } from '../helpers/campaignConfigurations'
import { OPT_IN } from '../store/action.types'
import analytics from '../utils/analytics'
import { CAMPAIGN_DAYS } from '../constants'
import { campaignStatus } from '../store/helpers'
export default {
  data() {
    return {
      CAMPAIGN_DAYS,
      isLoading: false,
    }
  },
  components: {
    PcButton,
    PcNotification,
  },
  props: {
    campaign: {
      type: Object,
    },
    day: {
      type: String,
      required: true,
    },
  },
  computed: {
    campaignConfiguration() {
      return this.campaign && CAMPAIGN_CONFIGURATIONS[this.campaign.id]
    },
    showCampaignText() {
      return this.campaignConfiguration && this.CAMPAIGN_DAYS.includes(this.day)
    },
    campaignText() {
      return this.$t(this.campaignConfiguration.optInText)
    },
    campaignId() {
      return this.campaign?.id.toLowerCase() || ''
    },
    campaignBanner() {
      return (
        this.campaignConfiguration &&
        require('../assets/campaigns/' + this.campaignConfiguration.banner)
      )
    },
    showCampaignPromotion() {
      return (
        this.campaign &&
        this.campaignConfiguration &&
        this.campaign.status !== 'Subscribed'
      )
    },
    showCampaignOfferPlaceholder() {
      return this.campaign && this.campaign.status === campaignStatus.Subscribed
    },
    optedInInfo() {
      const percent = this.campaign?.offerTemplate?.percent?.discountPercent
      const day = this.day
      const value = this.campaign.offerTemplate.qualifyingCriteria
        .minimumSpendValue
        ? this.$n(
            this.campaign.offerTemplate.qualifyingCriteria.minimumSpendValue /
              100,
            'currency'
          )
        : ''
      const date = this.$d(new Date(), 'short-campaign')
      return this.$t(this.campaign.id.toLowerCase() + '_you_opted_info', {
        percent,
        day,
        value,
        date,
      })
    },
    infoText() {
      if (!this.campaignConfiguration?.campaignInfo) return ''
      const amount =
        this.campaign?.offerTemplate?.percent?.discountPercent || ''
      const campaignDay = this.day
      const anyOffers = this.campaign.status === campaignStatus.Subscribed
      return anyOffers
        ? this.$t(this.campaignId + '_offer', {
            percent: amount,
            day: campaignDay,
          })
        : this.$t(this.campaignId + '_info')
    },
    campaignTermsAndConditionsLink() {
      return this.campaignConfiguration.termsAndConditionsLink
    },
    campaignAdditionalText() {
      return (
        this.campaignConfiguration.optInAdditionalText &&
        this.$t(this.campaignConfiguration.optInAdditionalText)
      )
    },
  },
  methods: {
    optOut() {
      this.$emit('opt-out', this.campaign)
    },
    async optIn() {
      if (this.campaign) {
        this.isLoading = true
        await this.$store.dispatch(OPT_IN, this.campaign.id)
        const msg = this.$t(this.campaignId + '_toast', {
          day: this.CAMPAIGN_DAYS[0],
        })
        this.$notify({
          type: 'success',
          message: msg.toString(),
        })
        this.isLoading = false
        analytics.OptInSuccess({
          campaign: this.campaign.id,
          percentDiscount:
            this.campaign?.offerTemplate?.percent?.discountPercent,
          minimumSpendValue:
            this.campaign.offerTemplate.qualifyingCriteria.minimumSpendValue /
            100,
          nActiveOffers: this.offersLength,
        })
      }
    },
  },
}
</script>
