export const OFFERS_TO_DISPLAY_ON_LIST = 'offersToDisplayOnList'
export const SHOW_DELETE_MODAL = 'showModal'
export const SHOW_OPT_OUT_MODAL = 'showOptOutModal'
export const NOTIFICATION = 'notification'
export const ENABLED_CAMPAIGNS = 'enabledCampaigns'
export const SERVER_ERROR = 'serverError'
export const IS_OFFER_INSIGHTS_LOADED = 'isOfferInsightsLoaded'
export const IS_OFFER_INSIGHTS_BANNER_ENABLED = 'isOfferInsightsBannerEnabled'
export const ACTIVE_OFFERS = 'activeOffers'
export const SUBSCRIBED_CAMPAIGNS = 'subscribedCampaigns'
export const ENABLED_CHEEKY_CAMPAIGNS = 'enabledCheekyCampaigns'
