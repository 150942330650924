import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import { pageTitle, PcToast, PcTooltip } from '@je-pc/ui-components'

import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'

Vue.mixin(pageTitle)
Vue.use(PcToast, {
  closeButton: null,
})
Vue.directive('pc-tooltip', PcTooltip())

Vue.config.productionTip = false

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
        },
      })
    },
    router,
    i18n,
    store,
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
