export default class Analytics {
  static PushEvent(action, label, offersData, category = 'engagement') {
    const dataLayer = window.dataLayer || []
    dataLayer.push({
      event: 'trackEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      offersData,
    })
  }

  static DeleteOfferStart() {
    this.PushEvent('form_delete_offer', 'start')
  }

  static DeleteOfferSuccess(nActiveOffers) {
    this.PushEvent('form_delete_offer', 'success', { nActiveOffers })
  }

  static DeleteOfferCancel() {
    this.PushEvent('form_delete_offer', 'cancel')
  }

  static DeleteOfferError(errorType) {
    this.PushEvent('error_delete_offer', errorType)
  }

  static OptInSuccess(offersData) {
    this.PushEvent('form_add_offer', 'success_opt_in', offersData)
  }

  static OptOutSuccess(offersData) {
    this.PushEvent('form_delete_offer', 'success_opt_out', offersData)
  }
}
