import { GlobalConfigService } from '@rmp/services'

const config = GlobalConfigService.getConfigs()

const exclusiveCampaignDays = {
  uk: ['Tuesday'],
  ie: ['Tuesday'],
  it: ['Wednesday'],
  es: [],
  au: [],
}

const cheekyTuesdayLink = {
  uk: 'https://partner.just-eat.co.uk/info/tuesdays-tcs/',
  ie: 'https://partner.just-eat.ie/info/cheeky-tuesday-tcs',
  it: 'https://shop.just-eat.it/it/content/super-mercoledi-condizioni',
  es: '',
  au: '',
}

export const TENANT = config.tenant
export const CAMPAIGN_DAYS = exclusiveCampaignDays[TENANT]

export const CHEEKY_CAMPAIGN_LINK = cheekyTuesdayLink[TENANT]
