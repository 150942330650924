import { CHEEKY_CAMPAIGN_LINK } from '../constants'
export const CAMPAIGN_CONFIGURATIONS = {
  sundayCampaign: {
    banner: 'sundaycampaign_banner.jpg',
    termsAndConditionsLink:
      'https://partnerblog.just-eat.co.uk/sundayspecial-tcs/',
    optInText: 'sundaycampaign_text',
    optInAdditionalText: 'sundaycampaign_additional_text',
    optedInText: 'sundaycampaign_you_opted_info',
    campaignInfo: true,
  },
  cheekyTuesday: {
    banner: 'cheekytuesday_banner.png',
    termsAndConditionsLink: CHEEKY_CAMPAIGN_LINK,
    optInText: 'cheekytuesday_text',
    optInAdditionalText: null,
    optedInText: '',
    campaignInfo: true,
  },
  cheekyWednesday: {
    banner: 'cheeky_wednesday.png',
    termsAndConditionsLink: CHEEKY_CAMPAIGN_LINK,
    optInText: 'cheekywednesday_text',
    optInAdditionalText: null,
    optedInText: '',
    campaignInfo: false,
  },
}
