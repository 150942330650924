<template>
  <div class="flex flex-wrap items-center my-4 relative text-sm">
    <slot name="image" />

    <span class="ml-2 flex-1 truncate pr-6" data-test-id="items-title">
      {{ title }} {{ items[0].name }}
      <span v-if="items[0].variationName">({{ items[0].variationName }})</span>
    </span>

    <div class="absolute right-0 top-0 -mt-1" v-if="items.length > 1">
      <pc-button
        @click="isOpened = !isOpened"
        class="transition-transform duration-300 ease-in-out transform"
        :class="{ 'rotate-180': isOpened }"
        icon="arrowDown"
        circle
        type="ghost"
        size="xsmall"
        data-test-id="open-items-btn"
      ></pc-button>
    </div>
    <collapse-transition>
      <div
        v-if="isOpened"
        class="w-full flex flex-col space-y-2 ml-5 pl-2 mt-2"
        data-test-id="opened-items"
      >
        <span
          :key="product.name + product.variationName"
          v-for="product in items.slice(1)"
        >
          {{ product.name }}
          <span v-if="product.variationName">
            ({{ product.variationName }})
          </span>
        </span>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { PcButton, CollapseTransition } from '@je-pc/ui-components'
export default {
  data() {
    return {
      isOpened: false,
    }
  },
  components: {
    CollapseTransition,
    PcButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
