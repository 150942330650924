<template>
  <div>
    <pc-notification
      v-if="showToEarlyToDisplay"
      type="info"
      data-test-id="no-offer-insights"
    >
      {{ $t('no_offer_insights_yet') }}
    </pc-notification>
    <pc-notification
      v-else-if="showProblemLoading"
      type="info"
      data-test-id="offer-insights-error"
    >
      {{ $t('offer_insights_error') }}
    </pc-notification>
    <template v-else>
      <div class="bg-white flex flex-col">
        <hr class="mb-3 border-grey-30" />
        <div class="flex">
          <div class="flex flex-col flex-1">
            <span
              class="text-sm"
              data-test-id="offer-insights-total-sales-label"
            >
              {{ $t('insights_sales') }}
            </span>
            <span
              class="text-xl"
              data-test-id="offer-insights-total-sales-value"
            >
              {{ $n(totalSales, 'currency') }}
            </span>
          </div>
          <div class="flex flex-col flex-1">
            <span
              class="text-sm"
              data-test-id="offer-insights-orders-count-label"
            >
              {{ $t('insights_orders_count') }}
            </span>
            <span
              class="text-xl"
              data-test-id="offer-insights-orders-count-value"
            >
              {{ orderCount }}
            </span>
          </div>
          <div class="flex flex-col flex-1">
            <span class="text-sm" data-test-id="offer-insights-order-avg-label">
              {{ $t('insights_avg_order_value') }}
            </span>
            <span class="text-xl" data-test-id="offer-insights-order-avg-value">
              {{ $n(averageValue, 'currency') }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { PcNotification } from '@je-pc/ui-components'
import { IS_OFFER_INSIGHTS_LOADED } from '../store/getters.type'
export default {
  props: {
    insights: {
      type: Object,
    },
  },
  components: {
    PcNotification,
  },
  computed: {
    isOfferInsightsLoaded() {
      return this.$store.getters[IS_OFFER_INSIGHTS_LOADED]
    },
    showToEarlyToDisplay() {
      return !this.insights && this.isOfferInsightsLoaded
    },
    showProblemLoading() {
      return !this.isOfferInsightsLoaded || this.reportDataIsBroken
    },
    reportDataIsBroken() {
      return this.insights?.orderCount < 0 || this.insights?.orderValueSum < 0
    },
    totalSales() {
      return this.insights?.orderValueSum || 0
    },
    orderCount() {
      return this.insights?.orderCount || 0
    },
    averageValue() {
      return this.insights?.orderCount
        ? this.insights.orderValueSum / this.insights.orderCount
        : 0
    },
  },
}
</script>
