import Vue from 'vue'
import VueRouter from 'vue-router'
import { beforeRouteEnterHook } from '@je-pc/auth'
import { trackPageTransition } from '@je-pc/tracking'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      name: 'offers',
      group: 'marketing',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/marketing/offers',
  routes,
})

router.beforeEach((to, from, next) => {
  beforeRouteEnterHook(to, from, next)
})

trackPageTransition(router)

export default router
