<template>
  <pc-modal
    v-model="isVisible"
    size="medium"
    escapable
    data-test-id="opt-out-modal"
  >
    <template slot="title">
      <span data-test-id="opt-out-modal-title">{{ $t('opt_out') }}</span>
    </template>
    <template slot="content">
      <p
        v-html="$t('opt_out_question', { campaignTitle, day })"
        data-test-id="opt-out-modal-question"
      ></p>
      <p class="campaign-text" data-test-id="opt-out-modal-note">
        {{ $t('wont_run_offers', { campaignTitle, day }) }}
      </p>
      <div class="mt-4 flex justify-end">
        <pc-button
          type="ghost"
          class="mr-4"
          @click="cancel"
          data-test-id="opt-out-modal-cancel-btn"
        >
          {{ $t('cancel') }}
        </pc-button>
        <pc-button
          :loading="isLoading"
          @click="optOut"
          data-test-id="opt-out-modal-confirm-btn"
        >
          {{ $t('opt_out') }}
        </pc-button>
      </div>
    </template>
  </pc-modal>
</template>

<script>
import { PcModal, PcButton } from '@je-pc/ui-components'
import { TOGGLE_OPT_OUT_MODAL } from '../store/mutation.type'
import { SHOW_OPT_OUT_MODAL } from '../store/getters.type'
export default {
  components: {
    PcModal,
    PcButton,
  },
  props: {
    day: {
      type: String,
    },
    campaignTitle: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    showOptOutModal() {
      return this.$store.getters[SHOW_OPT_OUT_MODAL]
    },
    isVisible: {
      get() {
        return this.showOptOutModal
      },
      set(value) {
        this.toggleOptOutModal(value)
      },
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    optOut() {
      this.$emit('opt-out')
    },
    toggleOptOutModal(value) {
      this.$store.commit(TOGGLE_OPT_OUT_MODAL, value)
    },
  },
}
</script>
