import { eventBus } from '@je-pc/utils'
import { SET_CAMPAIGNS } from '../mutation.type'
import { ENABLED_CAMPAIGNS, ENABLED_CHEEKY_CAMPAIGNS } from '../getters.type'
import { getCampaigns, optIn, optOut } from '../../services/http'
import { GET_CAMPAIGNS, GET_OFFERS, OPT_IN, OPT_OUT } from '../action.types'
import logger from '../../services/logger'

export const CampaignsModule = {
  state: {
    campaigns: [],
  },
  mutations: {
    [SET_CAMPAIGNS](state, campaigns) {
      state.campaigns = campaigns
    },
  },
  getters: {
    [ENABLED_CAMPAIGNS]: (state) => {
      return state.campaigns
    },
    [ENABLED_CHEEKY_CAMPAIGNS]: (state) => {
      return state.campaigns.filter((c) => c.id.includes('cheeky'))
    },
  },
  actions: {
    async [GET_CAMPAIGNS]({ commit }) {
      try {
        const campaigns = await getCampaigns()
        commit(SET_CAMPAIGNS, campaigns)
      } catch (error) {
        logger.error(error)
      }
    },
    async [OPT_IN]({ dispatch }, campaignId) {
      try {
        await optIn(campaignId)
        await dispatch(GET_OFFERS)
        await dispatch(GET_CAMPAIGNS)
        eventBus.emit('campaignStatusChange')
      } catch (error) {
        logger.error(error, { campaignId })
      }
    },
    async [OPT_OUT]({ dispatch }, campaignId) {
      try {
        await optOut(campaignId)
        await dispatch(GET_CAMPAIGNS)
        await dispatch(GET_OFFERS)
        eventBus.emit('campaignStatusChange')
      } catch (error) {
        logger.error(error, { campaignId })
      }
    },
  },
}
