export default {
  numberFormats: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
  messages: {
    offers_title: 'Offerte',
    offers_title_fmgc: 'Le tue offerte',
    setup_new_offer: 'Aggiungi offerta',
    active: 'In corso',
    want_delete_offer: 'Elimina offerta',
    are_you_sure: "Confermi di voler eliminare l'offerta <span>{offer}</span>?",
    delete: 'Elimina',
    cancel: 'Annulla',
    learn_more: 'Scopri di più',
    register_interest: 'Iscriviti all’offerta',
    register: 'Iscriviti',
    no_thanks: 'No, grazie',
    min_order: 'Ordine min.',
    all_day: 'Tutto il giorno',
    no_active_offers: 'Nessuna offerta',
    when_will_offer_run: 'Quando è valida l’offerta?',
    which_days: 'In questi giorni',
    monday: 'Lun',
    tuesday: 'Mar',
    wednesday: 'Mer',
    thursday: 'Gio',
    friday: 'Ven',
    saturday: 'Sab',
    sunday: 'Dom',
    currencySymbol: '€',
    Sunday: 'Domenica',
    Monday: 'Lunedì',
    Tuesday: 'Martedì',
    Wednesday: 'Mercoledì',
    Thursday: 'Giovedì',
    Friday: 'Venerdì',
    Saturday: 'Sabato',
    generic_error: 'È necessario risolvere il problema per proseguire.',
    problem_on_our_side:
      'Siamo spiacenti, abbiamo riscontrato un problema nei nostri sistemi. Ti preghiamo di riprovare.',
    smth_went_wrong: 'Si è verificato un errore',
    off: 'di sconto',
    percent_off: '{value}% di sconto',
    delete_toast: 'Il {value} sull’offerta è cancellato',
    or_over: 'o più',
    offer_created:
      'È stata aggiunta un’offerta del {value}% di sconto per tutti i clienti',
    save: 'Salva',
    opt_in: 'Iscriviti',
    opt_out: 'Rifiuta',
    consumer_segment_newtorestaurant: 'Solo nuovi clienti',
    consumer_segment_all: 'Tutti i clienti',
    offer_availability: 'Disponibilità dell’offerta',
    offer_target_question: 'A chi vuoi rivolgere la tua offerta?',
    next: 'Avanti',
    back: 'Indietro',
    consumer_segment_newtorestaurant_desc:
      'Attira nuovi clienti che non hanno mai ordinato dal tuo ristorante.',
    consumer_segment_all_desc:
      'Aumenta la tua visibilità tra i clienti e promuovi le vendite.',
    newtorestaurant_offer_created:
      'È stata aggiunta un’offerta del {value}% di sconto solo per nuovi clienti',
    offer_insights_time_period_note:
      'Questi risultati vengono aggiornati ogni 24 ore.<br/><br/>Mostrano gli ordini completati mentre la tua offerta era attiva.<br/><br/>Ti ricordiamo che i risultati delle offerte non includono modifiche né rimborsi relativi agli ordini.',
    offer_insights_time_period: 'Risultati degli ultimi 7 giorni',
    no_offer_insights_yet:
      'È ancora troppo presto per vedere l’andamento di questa offerta. Riprova più tardi.',
    offer_insights_error:
      'Si è verificato un problema con la visualizzazione dei tuoi risultati. Riprova più tardi.',
    insights_sales: 'Vendite',
    insights_orders_count: 'Ordini',
    insights_avg_order_value: 'Valore medio',
    what_hours_will_it_run: 'Quando sarà attiva?',
    time_duration_day: 'Tutto il giorno',
    time_duration_hours: 'Solo nelle ore selezionate',
    how_long_will_the_offer_run: 'Quanto durerà l’offerta?',
    how_long_will_the_offer_run_description:
      'Puoi eliminare un’offerta in qualsiasi momento dalla pagina principale delle offerte.',
    time_end_never: 'Finché non la eliminerò',
    time_end_selected: 'Per un periodo selezionato',
    time_end_value_week: 'settimana',
    time_end_value_weeks: 'settimane',
    time_duration_from: 'Da',
    time_duration_to: 'A',
    time_duration_dropdown_select: 'Seleziona',
    time_duration_from_validation: 'Seleziona un orario di inizio',
    time_duration_to_validation: 'Seleziona un orario di fine',
    time_duration_difference_validation: 'Seleziona la durata corretta',
    time_duration_reserved_time_slots_note:
      'Hai già un’offerta valida {timeSlots}. Non puoi impostare un’offerta in questo intervallo di tempo.',
    chose_offer_type: 'Quale tipo di offerta vorresti attivare?',
    percent_off_title: '% di sconto',
    percent_offer_desc: 'Ad es. il 20% di sconto per una spesa di 20€ o più.',
    free_item_title: 'Articolo gratuito',
    free_item_desc: 'Ad es. una bevanda gratuita quando si ordina un piatto.',
    offer_discount: 'Offri uno sconto',
    offer_discount_title: 'Indica l’importo di spesa idoneo e la % di sconto',
    qualifying_spend_title: 'Spesa minima per ottenere lo sconto',
    qualifying_spend_desc:
      'Indica l’importo di spesa idoneo e la percentuale di sconto',
    percent_discount_title: '% di sconto',
    percent_discount_desc:
      'Indica l’importo dello sconto che verrà applicato all’ordine',
    offer_type: 'Tipo di offerta',
    ends_on: 'Termina il',
    ends_when_deleted: 'Termina una volta cancellata',
    make_sure: 'Assicurati di aver selezionato tutte le tue preferenze',
    target_validation_msg:
      'Seleziona chi vorresti scegliere come target per la tua offerta',
    save_offer: 'Salva l’offerta',
    days_validator:
      'Seleziona i giorni per visualizzare le fasce orarie disponibili',
    offer_created_success: 'Hai creato una nuova offerta',
    start: 'Inizia',
    end: 'Fine',
    when_offer_start: "Quando partirà l'offerta?",
    immediately: 'Subito',
    on_set_date: 'In una data prestabilita',
    select_start_date: 'Seleziona la data di inizio',
    select_end_date: 'Seleziona la data di fine',
    birra_title: 'Birra Moretti Filtrata a Freddo',
    birra_label: 'Aggiungila al tuo menù',
    birra_description:
      'Vorresti una cassa GRATUITA di Birra Moretti Filtrata a Freddo da aggiungere al tuo menù su Just Eat?',
    birra_success_toast:
      'Hai registrato il tuo interesse per Birra Moretti Filtrata a Freddo',
    birra_opted_in_title: 'La tua cassa gratuita è in arrivo!',
    birra_next_step: 'In caso di domande, contatta il tuo referente Just Eat',
    new_offer_type: 'Nuovo tipo di offerta',
    new_offer_type_desc: 'Aumenta le vendite offrendo un articolo in omaggio',
    how_get: 'Come fanno i clienti ad avere un articolo in omaggio?',
    with_item_title: 'Acquistando un altro articolo',
    with_item_desc:
      'Ad es.: “Una bevanda gratuita ordinando un pasto completo”.',
    new: 'Novità',
    reach_title: 'Spendendo un importo minimo',
    reach_desc: 'Ad es.: Dolce gratuito con una spesa di almeno 20€.',
    offer_items: 'Articoli in offerta',
    pick_requirements: 'Scegli i requisiti per l’offerta',
    qualifying_items_title: 'Articoli inclusi nell’offerta',
    qualifying_items_min_spend:
      "Indica l'importo che il cliente deve spendere per ottenere l'articolo gratuito.",
    qualifying_items_desc:
      'Seleziona gli articoli che il cliente deve ordinare per avere un articolo in omaggio.',
    free_item_picker_desc:
      'Seleziona l’articolo o gli articoli gratuiti che il cliente può scegliere.',
    add_items: 'Aggiungi articoli',
    select_items: 'Seleziona gli articoli inclusi nell’offerta',
    select_free_items: 'Seleziona gli articoli gratuiti',
    select_all: 'Seleziona tutti gli articoli',
    search: 'Cerca',
    add_tems_to_offer: 'Aggiungi articoli all’offerta',
    free_items: 'Articoli gratuiti',
    num_items: 'articoli | {count} articolo | {count} articoli',
    select_value_and_items:
      'Imposta l’importo predefinito di spesa e gli articoli gratuiti',
    percent_off_what: '% di sconto su cosa?',
    selected_items_title: 'Articoli selezionati',
    selected_items_desc: 'Ad esempio 10% su tutte le pizze da 30 cm.',
    total_order_title: 'Importo totale dell’ordine',
    total_order_desc: 'Ad esempio il 10% in meno su una spesa di almeno 20€.',
    item_level_discount_title:
      'Seleziona gli articoli scontati e la % di sconto',
    discount_items_title: 'Articoli scontati',
    discount_items_desc:
      'Seleziona gli articoli su cui stai offrendo uno sconto.',
    percent_discount_items:
      'Imposta l’importo dello sconto che i clienti riceveranno su questi articoli.',
    select_items_discount: 'Seleziona gli articoli da scontare',
    free_item_with_item: 'Articolo omaggio con altro articolo',
    free_item_with_spend: 'Articolo omaggio con spesa minima',
    free: 'Gratis:',
    with: 'Con:',
    percent_off_items: '{value}% di sconto su articoli selezionati',
    invalid_menu_message:
      'Alcuni tipi di offerta non sono al momento disponibili a causa di problemi riscontrati nel tuo menù. Per risolverli, ti invitiamo a modificare il menù.',
    campaign_text: 'Campagna personalizzata',
    campaign_offer_info:
      'Questa offerta è stata creata come parte di una campagna generale del marchio o di una campagna a cui hai aderito in precedenza, per cui non può essere eliminata fino alla scadenza della campagna stessa.',
    bogof_title: 'Offerta 2x1',
    bogofhp_title: 'Comprare uno ottenere un mezzo prezzo',
    buy: 'Acquista:',
    veganuary_title: 'Veganuary 2023: Partecipa anche tu!',
    veganuary_description:
      "Hai in menù almeno un piatto vegetariano o vegano? Crea un qualsiasi tipo di offerta a tua scelta su uno o più piatti vegetariani o vegani per tutto gennaio 2023 per festeggiare Veganuary. Verrai inserito in un filtro speciale in piattaforma per aumentare la visibilità del tuo locale e nelle newsletter che inviamo settimanalmente ai clienti nella tua zona. Non perdere l'occasione di farti notare dai clienti!",
    veganuary_label: 'Fatti notare dai clienti',
    veganuary_opted_in_title: 'Grazie per la partecipazione!',
    veganuary_next_step:
      "Non vediamo l'ora di gustare i tuoi piatti vegetariani o vegani",
    veganuary_success_toast:
      'Hai registrato il tuo interesse per Veganuary 2023',
    terms_and_conditions: 'Termini e condizioni',
    offerte_title: 'Offerte da campioni',
    offerte_description:
      "Offri ai tuoi clienti uno sconto del 20% a partire da una spesa di 15€ e partecipa alle Offerte da Campioni nei giorni delle partite. Promuoveremo l'offerta e indirizzeremo i clienti verso di te. Affrettati, puoi iscriverti entro il 06/02/2023 per partecipare.",
    offerte_label: 'Ottimo per aumentare gli ordini',
    offerte_success_toast:
      'Hai registrato il tuo interesse per Offerte da campioni',
    offerte_opted_in_title: 'Offerte da campioni',
    offerte_next_step: 'Grazie per esserti iscritto!',
    offerte_legal_description:
      "Facendo clic su 'Registrati' confermi di aver letto la descrizione e di essere il solo responsabile di tutte le informazioni sugli alimenti (compresi gli allergeni) e i prezzi sul tuo menù.",
    offerte_legal_title: "Registrati all'iniziativa",
    value_off_title: 'Sconto su singolo articolo',
    value_off_desc:
      'Es.: -2€ sugli hamburger doppi o -5€ su ordini superiori a 20€.',
    value_off_what: 'Quale articolo vuoi scontare?',
    value_selected_items_title: 'Articoli selezionati',
    value_selected_items_desc: 'Es.: -5€ su tutte le pizze maxi.',
    value_off_page_title: 'Seleziona articoli e importo da scontare',
    value_off_subtitle: 'Importo da scontare in €',
    value_off_info:
      'Imposta l’importo da scontare ai clienti su articoli selezionati.',
    value_discount_items_title: 'Articoli scontati',
    value_discount_items_desc:
      'Scegli gli articoli su cui i clienti avranno uno sconto.',
    value_off: '{value}€ di sconto su articoli selezionati',
    value_off_error:
      'L’importo da scontare è superiore al prezzo di alcuni degli articoli selezionati.',
    value_off_item_error:
      'Il prezzo dell’articolo è inferiore all’importo da scontare',
    mozzarella_title: 'Promo estiva per i clienti',
    mozzarella_label: 'Perfetta per aumentare gli ordini in luglio',
    mozzarella_desc:
      'Partecipa alla promozione estiva di Just Eat "Assapora l\'estate" e offri ai clienti il 20% di sconto su ordini superiori a 20€ per il mese di luglio. Promuoveremo l\'offerta e porteremo molti clienti verso di te. Per partecipare, iscriviti entro il 28 giugno.',
    mozzarella_legal_title: 'Iscriviti',
    mozzarella_legal_desc:
      'Facendo clic su "Iscriviti" si conferma di aver letto la descrizione e di essere consapevoli diessere responsabili di tutte le informazioni sugli alimenti (compresi gli allergeni) e i prezzi del proprio menù.',
    mozzarella_opted_in_title: 'Partecipazione registrata!',
    mozzarella_opted_in_desc:
      "Ti sei iscritto correttamente alla promo di luglio Assapora l'Estate dedicata ai clienti Just Eat.",
    mozzarella_success_toast:
      'Hai registrato il tuo interesse per Promo estiva per i clienti',
    assapora_title: 'Promo estiva per i clienti',
    assapora_label: 'Perfetta per aumentare gli ordini di agosto',
    assapora_desc:
      'Partecipa alla promozione estiva di Just Eat "Assapora l\'estate" e offri ai clienti il 20% di sconto su ordini superiori a 20€ per il mese di agosto. Promuoveremo l\'offerta e porteremo molti clienti verso di te. Per partecipare, iscriviti entro il 31 luglio.',
    assapora_success_toast:
      'Hai registrato il tuo interesse per Promo estiva per i clienti',
    assapora_opted_in_title: 'Partecipazione registrata!',
    assapora_opted_in_desc:
      'Ti sei iscritto correttamente alla promo di agosto "Assapora l\'Estate" dedicata ai clienti Just Eat.',
    bogof_desc: 'Ad es. Compra 1 pizza e ne ricevi un’altra gratis.',
    bogo_items_desc:
      'Seleziona la categoria dal quale il cliente deve scegliere per ricevere un articolo gratuito.',
    bogo_free_item: 'Articolo gratuito',
    bogo_desc:
      'I clienti possono selezionare il loro articolo gratuito dalla categoria selezionata.',
    free_item_info:
      'Gli articoli gratuiti saranno gli stessi articoli inclusi nell’offerta.',
    items_picker_info_title:
      'Questa offerta è applicabile a 1 categoria menù per volta',
    items_picker_info_desc:
      'Per creare un’offerta Paghi 1, prendi 2 in più categorie, crea un’offerta separata per ciascuna categoria.',
    overlapping_title: 'Hai già un’offerta simile in vigore il {days}',
    overlapping_desc:
      'Non puoi creare due offerte simili allo stesso tempo. Prova un giorno o una data d’inizio diversi.',
    cheekywednesday_text:
      'Incrementa le vendite di metà settimana e attira più clienti con i nostri Super Mercoledì. In più avrai maggiore visibilità apparendo nella newsletter settimanale e nelle notifiche push ai clienti.',
    cheekywednesday_title: 'Super Mercoledì',
    cheekywednesday_toast: 'Aggiunto il Super Mercoledì',
    cheekywednesday_toast_deleted: 'Eliminato il Super Mercoledì',
    half_price_items: 'Metà prezzo:',
  },
  dateTimeFormats: {},
}
