import { groupBy, orderBy } from 'lodash-es'
import { eventBus } from '@je-pc/utils'
import {
  getOffers,
  deleteOffer,
  getOfferInsightsReport,
} from '../../services/http'
import { GET_OFFERS, GET_OFFER_INSIGHTS, DELETE_OFFER } from '../action.types'
import {
  SET_OFFERS,
  REMOVE_OFFER,
  SET_OFFER_INSIGHTS_DATA,
} from '../mutation.type'
import {
  OFFERS_TO_DISPLAY_ON_LIST,
  ACTIVE_OFFERS,
  IS_OFFER_INSIGHTS_BANNER_ENABLED,
  IS_OFFER_INSIGHTS_LOADED,
} from '../getters.type'
import { isNowBetween } from '../../helpers/dateTimeHelper'
import { OfferTypes } from '../../helpers/offerMap'
import logger from '../../services/logger'

export const OffersModule = {
  state: {
    offers: [],
    offerInsights: null,
  },
  getters: {
    [OFFERS_TO_DISPLAY_ON_LIST]: (state) => {
      const offers = state.offers
      offers.forEach((o) => {
        o.insights = (state.offerInsights || []).filter(
          (i) => i.offerId === o.offerId
        )[0]
      })
      const orderedOffers = orderBy(
        offers,
        [
          'qualifyingValue.value',
          'discountAmount',
          'consumerSegment',
          'percent',
        ],
        ['asc', 'desc', 'desc', 'asc']
      )
      return groupBy(orderedOffers, 'dayOfWeek')
    },
    [ACTIVE_OFFERS](state) {
      const validNowOffers = state.offers.filter((offer) =>
        isNowBetween(
          offer.dayOfWeek,
          offer.runningPeriod.startDate,
          offer.runningPeriod.endDate,
          offer.runningPeriod.startTime,
          offer.runningPeriod.endTime
        )
      )

      const groupedActiveOffers = groupBy(
        orderBy(validNowOffers, 'priority', 'desc'),
        (offer) => offer.offerType + '|' + offer.consumerSegment
      )

      return Object.keys(groupedActiveOffers).map(
        (key) => groupedActiveOffers[key][0]
      )
    },
    [IS_OFFER_INSIGHTS_LOADED]: (state) => state.offerInsights != null,
  },
  mutations: {
    [SET_OFFERS](state, offers) {
      state.offers = offers
    },
    [REMOVE_OFFER](state, offerId) {
      state.offers.splice(
        state.offers.findIndex((offer) => offer.offerId === offerId),
        1
      )
    },
    [SET_OFFER_INSIGHTS_DATA](state, offerInsights) {
      state.offerInsights = offerInsights
    },
  },
  actions: {
    async [GET_OFFERS]({ commit, dispatch }) {
      const offerTypes = Object.values(OfferTypes)
      try {
        const offers = await getOffers(offerTypes)
        commit(SET_OFFERS, offers)
        await dispatch(GET_OFFER_INSIGHTS)
      } catch (error) {
        logger.error(error)
      }
    },
    async [DELETE_OFFER]({ commit }, offerId) {
      try {
        await deleteOffer(offerId)
        commit(REMOVE_OFFER, offerId)
        eventBus.emit('offerDeleted')
      } catch (error) {
        logger.error(error, { offerId })
      }
    },
    async [GET_OFFER_INSIGHTS]({ commit, getters }) {
      if (!getters[IS_OFFER_INSIGHTS_BANNER_ENABLED]) {
        return
      }
      try {
        const insightsResponse = await getOfferInsightsReport()
        const insights = insightsResponse.reportData.items.map((i) => {
          return {
            dataExtractionDateUTC: new Date(
              insightsResponse.dataExtractionDateUTC
            ),
            offerId: i.offer_id.toString(),
            orderCount: i.order_count,
            orderValueSum: i.order_value_sum,
          }
        })
        commit(SET_OFFER_INSIGHTS_DATA, insights)
      } catch (error) {
        commit(SET_OFFER_INSIGHTS_DATA, null)
        logger.error(error)
      }
    },
  },
}
