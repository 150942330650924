import Vue from 'vue'
import Vuex from 'vuex'

import { CampaignsModule } from './campaigns'
import { OffersModule } from './offers'
import { getFeatureToggles } from '../services/http'
import { GET_CAMPAIGNS, GET_SETTINGS } from './action.types'
import {
  SET_FEATURE_TOGGLES,
  TOGGLE_DELETE_MODAL,
  TOGGLE_OPT_OUT_MODAL,
  SET_NOTIFICATION,
  RESET_NOTIFICATION,
} from './mutation.type'
import {
  IS_OFFER_INSIGHTS_BANNER_ENABLED,
  SHOW_DELETE_MODAL,
  SHOW_OPT_OUT_MODAL,
  NOTIFICATION,
  SERVER_ERROR,
} from './getters.type'
import i18n from '../i18n'
import logger from '../services/logger'

Vue.use(Vuex)

const baseNotification = {
  message: '',
  show: false,
  title: '',
  isServerError: false,
}

export default new Vuex.Store({
  state: {
    featureToggles: [],
    showDeleteModal: false,
    showOptOutModal: false,
    notification: { ...baseNotification },
    serverNotification: {
      message: i18n.t('problem_on_our_side'),
      show: true,
      title: i18n.t('smth_went_wrong'),
      isServerError: true,
    },
  },
  getters: {
    [SHOW_DELETE_MODAL]: (state) => state.showDeleteModal,
    [SHOW_OPT_OUT_MODAL]: (state) => state.showOptOutModal,
    [NOTIFICATION]: (state) => state.notification,
    [IS_OFFER_INSIGHTS_BANNER_ENABLED]: (state) => {
      return state.featureToggles.includes('offer-insights-banner')
    },

    [SERVER_ERROR]: (state) => state.serverNotification,
  },
  mutations: {
    [SET_FEATURE_TOGGLES](state, featureToggles) {
      state.featureToggles = featureToggles
    },
    [TOGGLE_DELETE_MODAL](state, visibility) {
      state.showDeleteModal = visibility
    },
    [TOGGLE_OPT_OUT_MODAL](state, visibility) {
      state.showOptOutModal = visibility
    },
    [SET_NOTIFICATION](state, notification) {
      Object.assign(state.notification, notification)
    },
    [RESET_NOTIFICATION](state) {
      Object.assign(state.notification, baseNotification)
    },
  },
  actions: {
    async [GET_SETTINGS]({ dispatch, commit }) {
      try {
        const featureToggles = await getFeatureToggles()
        commit(SET_FEATURE_TOGGLES, featureToggles.items)
        await dispatch(GET_CAMPAIGNS)
      } catch (error) {
        commit(SET_FEATURE_TOGGLES, [])
        logger.error(error)
      }
    },
  },
  modules: {
    CampaignsModule,
    OffersModule,
  },
})
