export default {
  numberFormats: {
    currency: {
      style: 'currency',
      currency: 'GBP',
    },
  },
  dateTimeFormats: {
    short: {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
    },
    'short-campaign': {
      day: 'numeric',
      month: 'long',
    },
  },
  messages: {
    offers_title: 'Offers',
    offers_title_fmgc: 'Your offers',
    setup_new_offer: 'Add new offer',
    active: 'Running now',
    want_delete_offer: 'Delete offer',
    are_you_sure:
      'Are you sure you want to delete this <span>{offer}</span> offer?',
    delete: 'Delete',
    cancel: 'Cancel',
    learn_more: 'Learn More',
    register_interest: 'Register Interest',
    register: 'Register',
    no_thanks: 'No thanks',
    min_order: 'Min. order',
    all_day: 'All day',
    no_active_offers: 'No offers',
    when_will_offer_run: 'When will the offer run?',
    which_days: 'On these days',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun',
    currencySymbol: '£',
    Sunday: 'Sunday',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    generic_error: 'Something needs sorting out before you can continue.',
    problem_on_our_side: 'It’s a problem at our end, sorry. Please try again.',
    smth_went_wrong: 'Something went wrong',
    off: 'off',
    percent_off: '{value}% off',
    delete_toast: '{value} offer deleted',
    or_over: 'or over',
    offer_created: '{value}% off all customers offer added',
    save: 'Save',
    cheekytuesday_text:
      'Give your customers 20% off orders over £15 every Tuesday. We’ll get your restaurant in front of new customers by featuring it in our email and online marketing.',
    sundaycampaign_text:
      'Help us build a fund to support frontline workers. Opt in to donate 10% of your food orders on Super Hero Sundays and Just Eat will match it.  We’ll also promote you in our marketing to customers in your area.',
    sundaycampaign_additional_text:
      'Please note:<ul><li>You can opt in or out of an upcoming Super Hero Sunday until 23:59 on the Saturday night before.</li><li>You can’t run other offers on Super Hero Sundays (3, 10, 17 and 24 May) whether your restaurant takes part or not.</li>',
    opt_in: 'Opt in',
    cheekytuesday_info:
      'To run a % off offer on Tuesdays, restaurants must opt-in to Cheeky Tuesdays',
    sundaycampaign_info:
      'If you have an offer running on Sunday, the offer(s) will continue until {date}. If you would like to make any changes to your existing {day} offers, please contact Customer Services.',
    cheekytuesday_offer:
      'This offer is part of the Cheeky {day}’s {percent}% off campaign - it will be automatically deleted at the end of the campaign.',
    sundaycampaign_offer:
      'This offer is part of the Super Hero Sundays campaign. It will be automatically deleted at the end of the Campaign at 23:59 on 24 May.',
    cheekytuesday_toast: 'Cheeky {day} added',
    sundaycampaign_toast: 'Super Hero {day} added',
    cheekytuesday_toast_deleted: 'Cheeky {day} deleted',
    cheekytuesday_toast_deleted_tonight:
      'Cheeky {day} offer will be deleted tonight',
    sundaycampaign_toast_deleted: 'Super Hero {day} deleted',
    sundaycampaign_toast_deleted_tonight:
      'Super Hero {day} offer will be deleted tonight',
    tc_apply: 'T&Cs apply.',
    cheekytuesday_title: 'Cheeky {day}',
    sundaycampaign_title: 'Super Hero {day}',
    delete_campaign_text:
      'This offer will continue running for the <span>rest of today</span>. After today, this offer will be deleted.',
    cheeky_only_offers:
      'Cheeky Tuesdays are the only offers that can run on Tuesday, whether your restaurant takes part or not.',
    cheeky_and_sunday_offers:
      'Cheeky Tuesdays and Super Hero Sundays are the only offers that can run on Tuesday and Sunday, whether your restaurant takes part or not.',
    opt_out: 'Opt out',
    opt_out_question:
      'Are you sure you want to opt out of this <span>{campaignTitle} {day}</span> offer?',
    wont_run_offers:
      'You won’t be able to run other offers on {campaignTitle} {day}s, whether your restaurant takes part or not.',
    ending_tonight: 'Ending tonight',
    you_opted: 'You’ve opted in!',
    cheekytuesday_you_opted_info:
      '{percent}% off orders {value} or more. Every {day} from {date}.',
    sundaycampaign_you_opted_info:
      'Donate 10% of your food order value - and we’ll match it.',
    cheekytuesday_alt: 'Cheeky Tuesday’s Campaign',
    sundaycampaign_alt: 'Super Hero Sunday’s Campaign',
    consumer_segment_newtorestaurant: 'New customers only',
    consumer_segment_all: 'All customers',
    offer_availability: 'Offer availability',
    offer_target_question: 'Who would you like to target?',
    next: 'Next',
    back: 'Back',
    consumer_segment_newtorestaurant_desc:
      'Attract new customers who have not ordered from your restaurant before.',
    consumer_segment_all_desc:
      'Get it seen by the most people and get the most sales.',
    newtorestaurant_offer_created:
      '{value}% off new customers only offer added',
    offer_insights_time_period_note:
      'These results are updated every 24 hours.<br/><br/>They show the orders completed while your offer was active.<br/><br/>Keep in mind, offer results don’t include any order adjustments or refunds.',
    offer_insights_time_period: 'Results from past 7 days',
    no_offer_insights_yet:
      'It’s too early to see how this offer is doing – check back soon.',
    offer_insights_error:
      'There’s a problem showing your results – check back soon.',
    insights_sales: 'Sales',
    insights_orders_count: 'Orders',
    insights_avg_order_value: 'Av. Value',
    unilever_fmgc_title: 'Free Ben & Jerry’s Ice Cream',
    unilever_fmgc_description:
      "Ben & Jerry’s is offering you a free case of Cookie Dough Ice Cream. Register your interest and they will deliver this product to you and we will add it to your menu.<br/><br/><div class='thick'>By clicking 'Register Interest' you confirm you have read the 'Learn More' section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.</div>",
    unilever_fmgc_success_toast:
      "You've registered for your free Ben & Jerry’s Ice Cream",
    uniliver_fmgc_opted_in_title: 'Your FREE case is coming!',
    uniliver_fmgc_next_step:
      "You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    unilever_fmgc_label: 'Great for increasing orders',
    monster_fmgc_title: 'Free Monster Energy Drink',
    monster_fmgc_description:
      "Monster is offering you a free case of Monster Energy 500ml AND Monster Mango 500ml. Register your interest and they will deliver this product to you and we will add it to your menu.<br/><br/><div class='thick'>By clicking 'Register Interest' you confirm you have read the 'Learn More' section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.</div>",
    monster_fmgc_success_toast:
      "You've registered for your free Monster Energy Drink",
    monster_fmgc_opted_in_title: 'Your FREE cases are coming!',
    monster_fmgc_next_step:
      "You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    monster_fmgc_label: 'Great for increasing orders',
    heineken_fmgc_title: 'Free Heineken Lager Beer',
    heineken_fmgc_description:
      "Heineken is offering you a free case of Heineken Premium 5% Lager Beer. Register your interest and they will deliver this product to you and we will add it to your menu.<br/><br/><div class='thick'>By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you require the appropriate alcohol licences in order to be able to sell alcohol and you remain responsible for all food information (including allergens) and pricing on your menu.</div>",
    heineken_fmgc_success_toast:
      "You've registered for your free Heineken Premium 5% Lager Beer",
    heineken_fmgc_opted_in_title: 'Thanks for signing up!',
    heineken_fmgc_next_step:
      "Your product will be delivered in the next 7 days. You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    heineken_fmgc_label: 'Great for increasing orders',
    mars_fmgc_title: 'Free Mars Wrigley Products',
    mars_fmgc_description:
      "Mars is offering you 3 free cases of Mars Wrigley Products – Maltesers Chocolate pouch bag 102g x13, Galaxy Minstrels Chocolate Pouch Bag 125g x 15 AND Extra Peppermint Chewing Gum 60 piece Bottle x 6.<br/><br/><div class='thick'>By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.</div>",
    mars_fmgc_success_toast:
      "You've registered for your free Maltesers Chocolate",
    mars_fmgc_opted_in_title: 'Thanks for signing up!',
    mars_fmgc_next_step:
      "Your product will be delivered in the next 7 days. You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    mars_fmgc_label: 'Great for increasing orders',
    cola_fmgc_title: 'Free Coca-Cola Zero Sugar and Sprite',
    cola_fmgc_description:
      "Coca-Cola is offering you a free case of Coca-Cola Zero Sugar 500ml and Sprite 500ml. Register your interest and they will deliver this product to you and we will add it to your menu.<br/><br/><div class='thick'>By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.</div>",
    cola_fmgc_success_toast:
      "You've registered for your free Coca-Cola Zero Sugar and Sprite",
    cola_fmgc_opted_in_title: 'Thanks for signing up!',
    cola_fmgc_next_step:
      "Your product will be delivered in the next 7 days. You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    cola_fmgc_label: 'Great for increasing orders',
    unilever_bogof_title: 'BEN & JERRY’s Promotion!',
    unilever_bogof_description:
      "Offer your customers Buy One Get One Free on Ben & Jerry’s 465ml Ice Cream.<br><br><div class='thick'>Register for this promotion now and receive £2.50 back for every tub given away. Learn more below.</div><br>Just Eat will manage the promotion, rebate & marketing, all you have to do is register interest below and watch your sales soar at no cost!",
    unilever_bogof_success_toast:
      "You've registered for Ben & Jerry’s promotion!",
    unilever_bogof_opted_in_title: 'Congratulations!',
    unilever_bogof_next_step:
      "The Ben & Jerry's Buy One Get One Free promotion begins 20/04/2021",
    unilever_bogof_label: 'Great for increasing orders',
    unilever_bogof_legal_description:
      "By clicking 'Register' you are confirming that you have read the information set out in the 'Learn More' section, and you acknowledge that Just Eat will share your account name, sort code and account number with Valassis UK Ltd for the purposes of transferring rebates to you from Unilever.",
    unilever_bogof_legal_title: 'Please confirm',
    joined_unilever_bogof_description:
      "<span style='color:red; font-weight:bold'>Ben & Jerry’s are also running a Buy One Get One Free on Ben & Jerry’s 465ml Ice Cream</span><br/><br/>Register for this promotion now and receive £2.50 back for every tub given away. Find out more <a class='font-bold text-blue-100' href='{link}' target='_blank'>here</a> and sign up below.<br/><br/>By clicking 'Register' you are confirming that you have read the information set out in the <a class='font-bold text-blue-100' href='{link}' target='_blank'>'Find out more'</a> section and you acknowledge that Just Eat will share your account name, sort code and account number with Valassis UK Ltd for the purpose of transferring rebates to you from Unilever. ",
    joined_unilever_bogof_title: 'BEN & JERRY’s Promotion!',

    pepsi_fmgc_title: 'Free Case of Pepsi Max',
    pepsi_fmgc_description:
      'PepsiCo is offering you a free case of Pepsi Max 330ml can. Register your interest and they will deliver this product to you and we will add it to your menu.</div>',
    pepsi_fmgc_success_toast:
      "You've registered for your free Case of Pepsi Max",
    pepsi_fmgc_opted_in_title: 'Thanks for signing up!',
    pepsi_fmgc_next_step:
      "Your product will be delivered in the next 7 days. You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    pepsi_fmgc_label: 'Great for increasing orders',

    what_hours_will_it_run: 'What hours will it run?',
    time_duration_day: 'All day',
    time_duration_hours: 'Selected hours only',
    how_long_will_the_offer_run: 'When will the offer end?',
    time_end_never: 'When I delete it',
    time_end_selected: 'For a selected time',
    time_end_value_week: 'week',
    time_end_value_weeks: 'weeks',
    time_duration_from: 'From',
    time_duration_to: 'To',
    time_duration_dropdown_select: 'Select',
    time_duration_from_validation: 'Please pick a start time',
    time_duration_to_validation: 'Please pick an end time',
    time_duration_difference_validation: 'Please select the correct duration',
    time_duration_reserved_time_slots_note:
      'You already have an offer between {timeSlots}. You can’t set up an offer over these times.',
    euro_final_fmgc_title: 'THE UEFA EURO 2020<sup>TM</sup> FINAL ',
    euro_final_fmgc_description:
      'Offer fans of your food 20% off orders over £15 and help them celebrate the UEFA EURO 2020<sup>TM</sup> Final.<br />Simply opt-in below, and Just Eat will run the customer promotion and marketing for you. Opting-in will override any other offers for one day only.',
    euro_final_fmgc_success_toast:
      "You've registered for UEFA EURO 2020 Final offer",
    euro_final_fmgc_opt_out_toast: 'UEFA EURO 2020 Final offer was deleted',
    euro_final_fmgc_opted_in_title: '20% OFF FOR YOUR CUSTOMERS THIS SUNDAY.',
    euro_final_fmgc_next_step:
      'Promotion will be live all day Sunday 11 July only, on orders over £15. Just Eat will run the customer promotion and marketing for you.',
    euro_final_fmgc_label: 'Great for increasing orders',
    euro_final_fmgc_button: 'Opt in',
    chose_offer_type: 'What type of offer would you like to run?',
    percent_off_title: '% off',
    percent_offer_desc: 'E.g. 10% off all pasta dishes or 15% off total order.',
    free_item_title: 'Free item',
    free_item_desc: 'E.g. Free drink with any meal purchase.',
    selected_items_title: 'Selected items',
    selected_items_desc: 'E.g. 10% off 12-inch pizzas.',
    total_order_title: 'Total order value',
    total_order_desc: 'E.g. 10% off your order when you spend £20.',
    percent_off_what: '% off what?',
    offer_discount: 'Offer discount',
    offer_discount_title: 'Set the qualifying spend and % discount',
    qualifying_spend_title: 'Minimum spend amount',
    qualifying_spend_desc: 'Set the qualifying spend and % discount',
    percent_discount_title: '% discount',
    percent_discount_desc:
      'Set the discount amount that customers will get off their order',
    offer_type: 'Offer type',
    redbull_fmgc_title: 'Free Case of Red Bull Energy Drink',
    redbull_fmgc_description:
      'Red Bull is offering you a free case of Red Bull Energy. Register your interest and they will deliver this product to you and we will add it to your menu.<br /><br />By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you require the appropriate alcohol licences in order to be able to sell alcohol and you remain responsible for all food information (including allergens) and pricing on your menu.',
    redbull_fmgc_success_toast:
      "You've registered for your Free Case of Red Bull Energy Drink",
    redbull_fmgc_opted_in_title: 'Thanks for signing up!',
    redbull_fmgc_next_step:
      'Thanks for signing up, your product will be delivered in the next 10 days.',
    redbull_fmgc_label: 'Great for increasing orders',
    ends_on: 'Ends on',
    ends_when_deleted: 'Ends when deleted',
    subway_notification_title:
      'Please, don’t create offers with discounts more than 25%',
    subway_notification_desc:
      'They will be ignored by the Subway system and orders will be cancelled.',
    nonLocalLegend_fmgc_title:
      'Opt in and receive a free case of Ben & Jerry’s<br />Cookie Dough 100ml',
    nonLocalLegend_fmgc_label: 'Great for increasing orders',
    nonLocalLegend_fmgc_description: '',
    nonLocalLegend_fmgc_success_toast:
      "You've registered for your free Ben & Jerry’s Ice Cream",
    nonLocalLegend_fmgc_opted_in_title: 'Thanks for signing up',
    nonLocalLegend_fmgc_next_step:
      'Your product will be delivered in the next 10 days.',
    nonLocalLegend_fmgc_legal_title: 'Please confirm',
    nonLocalLegend_fmgc_legal_description:
      "Ben & Jerry’s is offering you a free case of Ben & Jerry’s Cookie Dough 100ml. Register your interest and they will deliver this product to you and we will add it to your menu. <br /><br />By clicking 'Register Interest' you confirm you have read the 'Learn More' section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.",
    localLegend_fmgc_title:
      'Exclusive to Local Legends! Opt in and receive two FREE cases<br />of Ben & Jerry’s 100ml',
    localLegend_fmgc_label: 'Great for increasing orders',
    localLegend_fmgc_description: '',
    localLegend_fmgc_success_toast:
      "You've registered for your free Ben & Jerry’s Ice Cream",
    localLegend_fmgc_opted_in_title: 'Thanks for signing up',
    localLegend_fmgc_next_step:
      'Your product will be delivered in the next 5 days.',
    localLegend_fmgc_legal_title: 'Please confirm',
    localLegend_fmgc_legal_description:
      "Ben & Jerry’s is offering Local Legends the chance to claim two FREE cases of Ben & Jerry’s (Cookie Dough 100m & Choc Fudge Brownie 100ml). Register your interest and they will deliver this product to you and we will add it to your menu.<br /><br />By clicking 'Register Interest' you confirm you have read the 'Learn More' section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.",
    unilever_bogof_oct_description:
      "Offer your customers Buy One Get One Free on Ben & Jerry’s 100ml Ice Cream.<br><br><br><div class='font-bold'>Register for this promotion now and receive £1  back for every 100ml tub given away. Find out more below.</div><br /><br />Just Eat will run the promotion, rebate & marketing, all you have to do is register below and watch your sales soar at no cost!",
    unilever_bogof_oct_next_step: 'Promotion begins <b>OCT 25th</b>',
    unilever_bogof_oct_legal_description:
      "By clicking 'Confirm' you are confirming that you have read the information set out in the 'Find out more' section, and you acknowledge that Just Eat will share your account name, sort code and account number with Valassis UK Ltd for the purposes of transferring rebates to you from Unilever.",
    cocaCola_b2b_title:
      'Opt in and receive a free case of Fanta Orange 1.25L<br />and  Coca-Cola Zero Sugar 1.25L',
    cocaCola_b2b_label: 'Great for increasing orders',
    cocaCola_b2b_description: '',
    cocaCola_b2b_success_toast:
      "You've registered for your free Coca-Cola and Fanta",
    cocaCola_b2b_opted_in_title: 'Thanks for signing up',
    cocaCola_b2b_next_step:
      'Your product will be delivered in the next 10 days.',
    cocaCola_b2b_legal_title: 'Please confirm',
    cocaCola_b2b_legal_description:
      "Coca-Cola is offering you a free case of Coca-Cola Zero Sugar 1.25L and Fanta Orange 1.25L. Register your interest and they will deliver this product to you and we will add it to your menu. <br /><br /> By clicking ‘Register Interest’ you confirm you have read the 'Learn More' section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.",
    terms_and_conditions: 'Terms & Conditions',
    make_sure: "Make sure you've made all your selections",
    target_validation_msg: 'Please select who you would like to target',
    save_offer: 'Save offer',
    days_validator: 'Please select days to get available time slots',
    offer_created_success: 'New offer created successfully',
    start: 'Start',
    end: 'End',
    mondelez_b2b_label: 'Great for increasing orders',
    mondelez_b2b_title:
      'Opt in and receive two free cases of<br />Cadbury Dairy Milk Giant Buttons Chocolate Bag 119g free',
    mondelez_b2b_desc: '',
    mondelez_success_toast:
      "You've registered for your free Cadbury Dairy Milk Giant Buttons Chocolate Bag",
    mondelez_opted_in_title: 'Thanks for signing up',
    mondelez_next_step: 'Your product will be delivered in the next 10 days.',
    mondelez_legal_description:
      'Mondelez is offering you two free cases of Cadbury Dairy Milk Giant Buttons Chocolate Bag 119g . Register your interest and they will deliver this product to you and we will add it to your menu.<br/><br/>By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.',
    mondelez_legal_title: 'Please confirm',
    ben_jerry_label: 'Great for increasing orders',
    ben_jerry_title: 'Opt in and receive two FREE cases of Ben & Jerry’s 100ml',
    ben_jerry_desc: '',
    ben_jerry_success_toast:
      "You've registered for two FREE cases of Ben & Jerry’s 100ml",
    ben_jerry_opted_in_title: 'Thanks for signing up',
    ben_jerry_next_step: 'Your product will be delivered in the next 10 days.',
    ben_jerry_legal_description:
      'Ben & Jerry’s is offering the chance to claim two FREE cases of Ben & Jerry’s to add to your Just Eat Menu (Cookie Dough 100m & Choc Fudge Brownie 100ml). <br /><br /> How does it work: <br /> 1. Register your interest through link below<br> 2. Ben &amp; Jerry&rsquo;s will deliver the products to your doorstep<br> 3. JustEat will update your menu <br> 4. Increase your order value &amp; profits for your business!<br> <br />By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.',
    ben_jerry_legal_title: 'Please confirm',
    mars_booker_label: 'Great for increasing orders',
    mars_booker_title:
      'Opt in and receive a Booker Voucher <br /> for three FREE cases of Mars Wrigley product worth £40!',
    mars_booker_desc: '',
    mars_booker_success_toast:
      "You've registered for FREE cases of Mars Wrigley product",
    mars_booker_opted_in_title: 'Thanks for signing up',
    mars_booker_next_step:
      'Claim your voucher <a class="underline" target="_blank" href="https://partner-offers-module.just-eat.co.uk/voucher.pdf">HERE!</a>',
    mars_booker_legal_description:
      'Mars Wrigley is offering the chance to claim a Booker voucher for three FREE cases of Mars Wrigley Product to add to your Just Eat Menu.<br><br>1x Galaxy Minstrels Pouch 125g box of 15,<br>1x Wrigleys Extra Spearmint Gum - box of 30 Packs,<br>1x Wrigleys Extra Peppermint Gum - box of 30 Packs<br><br> How does it work:<br>- Access the Voucher through the link below<br>- Print the voucher and show in branch at any Booker/Macro depot<br>- Just Eat to update your menu once voucher has been redeemed<br>- Increase your order value & profits for your business!<br><br>By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.',
    mars_booker_legal_title: 'Please confirm',
    how_get: 'How does the customer get a free item?',
    with_item_title: 'By buying another item',
    with_item_desc: 'E.g. Get a free drink when you order a meal.',
    reach_title: 'By spending a minimum amount',
    reach_desc: 'E.g. Get a free dessert when you spend £20.',
    offer_items: 'Offer items',
    pick_requirements: 'Pick the requirements in the offer',
    qualifying_items_title: 'Qualifying items',
    qualifying_items_min_spend:
      'Set the amount the customer must spend to get the free item.',
    qualifying_items_desc:
      'Select the items the customer must order to get the free item.',
    free_item_picker_desc:
      'Select the free item (or items) that <b>the customer can choose one</b> from.',
    add_items: 'Add items',
    select_items: 'Select qualifying item(s)',
    select_free_items: 'Select free item(s)',
    select_all: 'Select all items',
    search: 'Search',
    select_value_and_items: 'Set the qualifying spend and free items',
    num_items: 'items | {count} item | {count} items',
    add_tems_to_offer: 'Add item(s) to offer',
    free_item_with_item: 'Free item with item',
    free_item_with_spend: 'Free item with spend',
    free: 'Free:',
    with: 'With:',
    uefa_final_title: '25% off for your customers',
    uefa_final_description:
      "Make the most of the UEFA Champions League Final on Saturday 28 May. Offer customers 25% off orders of £20+ and boost your sales. Opt in and we'll get your restaurant in front of even more customers through our marketing.",
    uefa_final_success_toast:
      "You've registered for UEFA Champions League Final offer",
    uefa_final_opt_out_toast:
      "You've unsubscribed from UEFA Champions League Final offer",
    uefa_final_opted_in_title: "You've signed up",
    uefa_final_next_step:
      '25% off on Saturday 28 May for the UEFA Champions LeagueFinal. Offer will cease at midnight 28 May. Full terms <a class="underline" target="_blank" href="https://partner.just-eat.co.uk/info/25-uefa-champions-league-tcs">here</a>.',
    uefa_final_label: 'Great for increasing orders',
    uefa_final_button: 'Opt in',
    uefa_final_terms: 'Terms & Conditions',
    coke_zero_title:
      'Opt in and receive a free case of Coca Cola Zero Sugar and Sprite',
    coke_zero_description: '',
    coke_zero_legal_description:
      'Coca-Cola is offering you a free case of Coca-Cola Zero Sugar 330ml and Sprite 330ml . Register your interest and they will deliver this product to you and we will add it to your menu.<br><br>By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.',
    coke_zero_legal_title: 'Please register',
    coke_zero_success_toast:
      "You've registered for your free Coca-Cola and Sprite",
    coke_zero_opted_in_title: 'Thanks for signing up',
    coke_zero_next_step: 'Your product will be delivered in the next 10 days.',
    coke_zero_label: 'Great for increasing orders',
    coke_zero_legend_title:
      'Opt in and receive a free case of Coca Cola Zero Sugar and Sprite',
    coke_zero_legend_description: '',
    coke_zero_legend_legal_description:
      'Coca-Cola is offering you a free case of Coca-Cola Zero Sugar 330ml (24 units), Sprite 330ml (24 units), and Coca-Cola Zero 1.75L (6 units) (delivered separately in up to 14 days). Register your interest and they will deliver this product to you and we will add it to your menu.<br><br>By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.',
    coke_zero_legend_legal_title: 'Please register',
    coke_zero_legend_success_toast:
      "You've registered for your free Coca-Cola and Sprite",
    coke_zero_legend_opted_in_title: 'Thanks for signing up',
    coke_zero_legend_next_step:
      'Your product will be delivered in the next 10 days.',
    coke_zero_legend_label: 'Great for increasing orders',
    when_offer_start: 'When will the offer start?',
    immediately: 'Immediately',
    on_set_date: 'On a set date',
    select_start_date: 'Please select start date',
    select_end_date: 'Please select end date',
    starts_on: 'Starts on',
    redbull_title: 'Free Red Bull Energy Drink',
    redbull_description:
      'Red Bull is offering you a free case of Red Bull Energy. Register your interest and they will deliver this product to you and we will add it to your menu.',
    redbull_success_toast: "You've registered for your free Red Bull Energy",
    redbull_opted_in_title: 'Your FREE case is on the way!',
    redbull_next_step:
      'Thanks for signing up, your product will be delivered in the next 10 days.',
    redbull_label: 'Great for increasing orders',
    invalid_menu_message:
      "Some offer types are currently unavailable due to issues with your menu. Please <a class='text-blue-100' href='{link}'>edit your menu</a> to fix this issue.",
    cheekier_title: 'Sign up to Cheekier Tuesday now!',
    cheekier_description:
      'Offer your customers a free Coca-Cola can and 20% off orders over £15 with every Cheeky Tuesday order placed in August.<br /><br />Register for this promotion before 25 July and receive £0.38 back for every Coca Cola given away on Tuesdays in August.<br /><br />Just Eat will run the promotion, rebate & marketing, all you have to do is register below!',
    cheekier_success_toast: "You've registered for Cheekier Tuesday",
    cheekier_opted_in_title: 'Your Tuesday just got Cheekier!',
    cheekier_next_step:
      'Congratulations you are signed up to Cheekier Tuesday! The offer will be live from Tuesday 2nd August and every Tuesday thereafter for the month. Promotion begins <b>2 Aug and ends 30 Aug.</b>',
    cheekier_label: 'Great for increasing orders',
    cheekier_legal_description:
      'By clicking "Confirm" you are confirming that you have read the information set out in the T&C’s, and you acknowledge that Just Eat will share your account name, sort code and account number with Valassis UK Ltd for the purposes of transferring rebates to you from Coca Cola.',
    cheekier_legal_title: 'Please register',
    item_level_discount_title: 'Select the discounted items and % discount',
    discount_items_title: 'Discounted items',
    discount_items_desc: 'Select the items that you’re offering a discount on.',
    percent_discount_items:
      'Set the discount amount that customers will get off these items.',
    select_items_discount: 'Select item(s) to discount',
    cheekier_optout_toast: "You've unsubscribed from Cheekier Tuesday",
    new: 'New',
    new_offer_type: 'New offer type',
    new_offer_type_desc: 'Increase sales with a free item giveaway',
    percent_off_items: '{value}% off selected items',
    tango_title: 'Opt in and receive one FREE case of Tango Orange',
    tango_description:
      'Britvic are offering you the chance to claim a FREE case of Tango Orange to add to your Just Eat Menu.',
    tango_success_toast: "You've registered for free Tango Orange",
    tango_opted_in_title: 'Your FREE case is coming!',
    tango_next_step:
      'Thanks for signing up, your product will be delivered in the next 10 days.',
    tango_label: 'Boost your average order value!',
    tango_legal_description:
      'How does it work:<br />1. Register your interest through link below<br />2. Britvic will deliver the products to your doorstep<br />3. Just Eat will update your menu<br />4. Increase your order value & profits for your business!<br /><br />By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.',
    tango_legal_title: 'Please register',
    tango_optout_toast: "You've unsubscribed from free Tango Orange",
    bogof_title: 'Buy 1 get 1 free',
    bogof_desc: 'E.g. Buy 1 pizza get another pizza free.',
    bogofhp_title: 'Buy 1 get 1 half price',
    buy: 'Buy:',
    campaign_text: 'Custom campaign',
    campaign_offer_info:
      "This offer was created as part of a brand-wide campaign or a campaign you opted into previously, so it can't be removed until the campaign ends.",
    free_items: 'Free items',
    free_monster_title: 'Claim two FREE cases of Monster',
    free_monster_label: 'Boost your average order value!',
    free_monster_description:
      'Moster are offering you the chance to claim two FREE cases - Pipeline Punch & Mango Loco',
    free_monster_success_toast: "You've registered for FREE cases of Monster",
    free_monster_opted_in_title: 'Your FREE cases are coming!',
    free_monster_next_step:
      'Thanks for signing up, your product will be delivered in the next 10 days.',
    world_cup_title: 'BEN & JERRY’s Promotion!',
    world_cup_label: 'Great for increasing orders',
    world_cup_description:
      'World Cup Special - Offer your customers Buy One Get One Free on Ben & Jerry’s 100ml Ice Cream during the world cup knockout stages in December<br /><br /><b>Register for this promotion now and receive £1  back for every Ben & Jerry’s 100ml tub given away. Find out more below.</b><br /><br />Just Eat will run the promotion, rebate & marketing, all you have to do is register below and watch your sales soar at no cost!',
    world_cup_success_toast: "You've registered for BEN & JERRY’s Promotion",
    world_cup_legal_title: 'Please register',
    world_cup_legal_description:
      'By clicking "Confirm" you are confirming that you have read the information set out in the "Find out more" section, and you acknowledge that you have read the promotional <a class="underline" target="_blank" href="https://partner.just-eat.co.uk/info/world-cup-2022-tcs">Terms and Conditions</a>.',
    world_cup_opted_in_title: 'Congratulations',
    world_cup_next_step: 'The promotion will begin on December 5th',
    new_item_lvl_offer:
      'Boost sales by offering customers a free item or % off an item',
    pepsimax_title: 'Claim a FREE case of PepsiMax',
    pepsimax_label: 'Boost your average order value!',
    pepsimax_description:
      'PepsiMax are offering you the chance to claim a FREE case of 24 cans',
    pepsimax_success_toast: "You've registered for FREE case of PepsiMax",
    pepsimax_opted_in_title: 'Your FREE case is coming!',
    pepsimax_next_step:
      'Thanks for signing up, your product will be delivered in the next 10 days.',
    wc_ben_title: "Ben and Jerry's 100ml promotion",
    wc_ben_label: 'Great for increasing orders',
    wc_ben_description:
      'World Cup Special - Offer your customers Buy One Get One Free on Ben & Jerry’s 100ml Ice Cream <br /><br />Just Eat will run the promotion, rebate & marketing, all you have to do is register below and watch your sales soar at no cost!',
    wc_ben_success_toast: "You've registered for BEN & JERRY’s Promotion",
    wc_ben_optout_toast: "You've unsubscribed from BEN & JERRY’s Promotion",
    wc_ben_legal_title: 'Opt In to the campaign',
    wc_ben_legal_button_text: 'Confirm',
    wc_ben_legal_description:
      'By clicking "Confirm" you are confirming that you have read the information set out in the "Find out more" section, and you acknowledge that you have read the promotional <a class="underline" target="_blank" href="https://partner.just-eat.co.uk/info/world-cup-2022-tcs">Terms and Conditions</a>.',
    wc_ben_opted_in_title: 'Congratulations',
    wc_ben_next_step:
      "You are opted into the Ben & Jerry's 100ml buy one get one free promotion. Running from the 1st to the 18th of December.<br /><br />To opt-out of the promotion please click 'opt out' below. By clicking opt out you will be removed from the promotion. For any items given away during the promotion you will be rebated in full as stated in the promotional terms & conditions. Please see the full rebate details in the learn more section below",
    benj_meal_title: "Free Ben & Jerry's Meal Deal",
    benj_meal_description:
      "Make up to £3.15 with every Ben & Jerry's you sell as part of a meal deal!<br/><br/>Earn EXTRA cash by making meal deals with Ben & Jerry's.<br />Follow these easy steps to get involved:<br /> Step 1) Create a delicious meal deal with your top selling items<br />Step 2) ADD a Ben & Jerry's tub to the meal deal<br />Step 3) Meal deal must be live from the 3-16 of April<br />Step 4) Get paid up to £3.15 for EVERY tub you sell!<br /><br />By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.",
    benj_meal_label: 'Great for increasing orders',
    benj_meal_legal_title: 'Opt In to the campaign',
    benj_meal_legal_desc:
      "Thank you for your interest in the Ben & Jerry's Ice cream meal deal promotion. To participate, kindly confirm your opt-in and that you have read and agree to the promotional terms and conditions. From April 3-16 2023, you can enjoy a rebate of £3.15 for every 465ml product and £1 for every 100ml product of Magnum or Ben & Jerry's ice cream sold as part of a meal deal. Please note that only new meal deal listings for this promotion will be considered eligible. Don't miss out on this limited-time offer!<br /><br />By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you require the appropriate alcohol licences in order to be able to sell alcohol and you remain responsible for all food information (including allergens) and pricing on your menu.",
    benj_meal_success_toast:
      "You've registered for Free Ben & Jerry's Meal Deal",
    benj_meal_opted_in_title: 'Get your menus ready for 3rd April',
    benj_meal_opted_in_desc:
      'You can see all the <a class="font-bold text-blue-100" href="{link}">next steps here</a>.',
    value_off_title: 'Amount off',
    value_off_desc: 'E.g. £2 off burger combos or £5 off orders over £20.',
    value_off_what: '£ amount off what?',
    value_selected_items_title: 'Selected items',
    value_selected_items_desc: 'E.g. £5 off all 12-inch pizzas.',
    value_off_page_title: 'Select items and amount off',
    value_off_subtitle: '£ amount off',
    value_off_info: 'Set the amount customers will get off selected items.',
    value_discount_items_title: 'Discounted items',
    value_discount_items_desc:
      'Select the items customers will receive a discount on.',
    value_off: '£{value} off selected items',
    value_off_error:
      'This discount amount is higher than some of your selected items’ value.',
    value_off_item_error: 'Item price is less than discount amount',
    cola_title: 'Free cases of Coca-Cola Zero Sugar',
    cola_label: 'Great for increasing AOV',
    cola_desc:
      'Coca Cola is offering two free cases of Coca-Cola Zero Sugar for you to add to your menu FREE of charge.',
    cola_success_toast:
      'Thanks for signing up - your product will be delivered in the next 10 days.',
    cola_opted_in_title: 'Your FREE cases are coming!',
    cola_opted_in_desc:
      'You can see all the <a class="font-bold text-blue-100" href="{link}">next steps here</a>.',
    redb_title: 'Free Case of Red Bull Energy Drink',
    redb_label: 'Great for increasing order value',
    redb_success_toast:
      "You've registered for Free Case of Red Bull Energy Drink",
    redb_opted_in_title: 'Thanks for signing up',
    redb_opted_in_desc: 'Your product will be delivered in the next 10 days.',
    redb_legal_title: 'Please register',
    redb_legal_desc:
      'Red Bull is offering you a free case of Red Bull Energy. Register your interest and they will deliver this product to you and we will add it to your menu.<br /><br />By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you require the appropriate alcohol licences in order to be able to sell alcohol and you remain responsible for all food information (including allergens) and pricing on your menu.',
    ucl_final_title: 'Offer 25% off for your customers',
    ucl_final_desc:
      "Make the most of the UEFA Champions League Final on Saturday 10 June. Offer customers 25% off orders of £20+ and boost your sales. Opt in and we'll get your restaurant in front of even more customers through our marketing.",
    ucl_final_success_toast: "You've registered for 25% UEFA Champions League",
    ucl_final_opted_in_title:
      "Score! You've opted into the UEFA Champions League Final offer",
    ucl_final_opted_in_desc:
      "You’ve successfully signed up for the UEFA Champions League Final promo on Saturday 10 June to offer customers 25% off orders of £20+. We'll promote the offer through our marketing.",
    ucl_final_opt_out_toast:
      "You've unsubscribed from 25% UEFA Champions League",
    alcohol_title: 'TWO FREE Cases of Beer',
    alcohol_label: 'Increase your average order value',
    alcohol_desc:
      "Fancy getting 2 free cases of beer to add to your menu and increase profit potential? Stella Artois, Cobra and Corona are offering you two free cases of beer depending on your cuisine. By clicking 'Register Interest' you confirm you have read the Terms & Conditions, have an alcohol licence that enables the sale of alcohol for off premises consumption and consent to data being shared with our 3rd Party for the explicit purpose of this promotion.",
    alcohol_success_toast: "You've registered for TWO FREE Cases of Beer",
    alcohol_opted_in_title: 'We will reach out shortly',
    alcohol_legal_title: 'Please register',
    alcohol_legal_desc:
      "If you hold an alcohol licence that enables the sale of alcohol for off premises consumption, register your interest for your chance to be part of our exciting beer trial. Just Eat will select 500 Partners for the trial based on the available business opportunities. If you’re selected, you’ll get two free cases of beer worthup to £132 which will be added to your Just Eat menu, ready for you to sell.<br /><br /> By clicking 'Register Interest' you confirm you have read the Terms & Conditions, have an alcohol licence that enables the sale of alcohol for off premises consumption and consent to data being shared with our 3rd Party for the explicit purpose of this promotion.",
    benjerry4_title: "4 FREE cases of Ben & Jerry's",
    benjerry4_label: 'Great for increasing revenue',
    benjerry4_desc:
      'Ben & Jerry’s is offering the chance to claim four FREE cases of Ben & Jerry’s to add to your Just Eat Menu (2x cases of 100ml& 2x free cases 465ml).<br /><br />How does it work:<br />Register your interest through link below<br /> Ben & Jerry’s will deliver the products to your doorstep<br /> Just Eat will update your menu <br />Increase your order value & profits for your business!<br /><br />By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you remain responsible for all food information (including allergens) and pricing on your menu.',
    benjerry4_success_toast:
      "You've registered for 4 FREE cases of Ben & Jerry's",
    benjerry4_opted_in_title: 'Your FREE cases are coming',
    benjerry4_opted_in_desc:
      'Thanks for signing up, your product will be delivered in the next 10 days.',
    free_sprite_title: 'Free case of Sprite & Sprite Zero',
    free_sprite_label: 'Boost your average order value!',
    free_sprite_desc:
      'Sprite are offering you the chance to claim 2 x FREE cases of 24 cans',
    free_sprite_success_toast:
      "You've registered for Free case of Sprite & Sprite Zero",
    free_sprite_opted_in_title: 'Your FREE case is coming!',
    free_sprite_opted_in_desc:
      'Thanks for signing up, your product will be delivered in the next 10 days.',
    bogo_items_desc:
      'Select the category that the customer must choose from to get a free item.',
    bogo_free_item: 'Free item',
    bogo_desc:
      'Customers can select their second item from the qualifying items you’ve selected. <b>The customer will get the cheapest of the two items free.</b>',
    free_item_info: 'Free items will be the same as qualifying items.',
    items_picker_info_title:
      'This offer can be applied to 1 menu category at a time',
    items_picker_info_desc:
      'To run a Buy 1 get 1 free offer in multiple categories, please create a separate offer for each category.',
    overlapping_title: 'You already have a similar offer running on {days}',
    overlapping_desc:
      'You can’t run two similar offers at the same time. Try a different day or start date.',
    red_title: 'Free Case of Red Bull Energy Drink',
    red_label: 'Great for increasing orders',
    red_desc:
      'Red Bull is offering you a free case of Red Bull Energy. Register your interest and they will deliver this product to you and we will add it to your menu. <br /><br />By clicking ‘Register Interest’ you confirm you have read the ‘Learn More’ section and that you understand you require the appropriate alcohol licences in order to be able to sell alcohol and you remain responsible for all food information (including allergens)',
    red_success_toast:
      'Thanks for signing up, your product will be delivered in the next 10 days.',
    red_opted_in_title: 'Your FREE cases are coming!',
    red_opted_in_desc:
      "You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    fanta_title: 'Free cases of Fanta Regular & Zero',
    fanta_label: 'Great for increasing AOV',
    fanta_desc:
      'Fanta is offering two free cases of Fanta Regular & Fanta Zero for you to add to your menu FREE of charge',
    fanta_success_toast:
      'Thanks for signing up - your product will be delivered in the next 10 days.',
    fanta_opted_in_title: 'Your FREE cases are coming!',
    fanta_opted_in_desc:
      "You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    sprite_title: 'Free cases of Sprite and Coke Zero',
    sprite_desc:
      'Coca Cola are offering two free cases of Sprite & Coca Cola Zero Sugar for you to add to your menu FREE of charge',
    sprite_label: 'Great for increasing AOV',
    sprite_success_toast:
      'Thanks for signing up - your product will be delivered in the next 10 days.',
    sprite_opted_in_title: 'Your FREE cases are coming!',
    sprite_opted_in_desc:
      "You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    uefa_title: '25% off for the UEFA Champions League Final',
    uefa_desc:
      "Make the most of the UEFA Champions League Final on Saturday 1 June. Offer customers 25% off orders of £20+ and boost your sales. Opt in and we'll get your restaurant in front of even more customers through our marketing.",
    uefa_success_toast:
      "You've registered for UEFA Champions League Final offer",
    uefa_opt_out_toast:
      "You've unregistered from UEFA Champions League Final offer",
    uefa_opted_in_title:
      "Score! You've opted into the UEFA Champions League Final offer",
    uefa_opted_in_desc:
      "You’ve successfully signed up for the UEFA Champions League Final promo on Saturday 1 June to offer customers 25% off orders of £20+. We'll promote the offer through our marketing.",
    benjer_title: "Ben & Jerry's: Buy 2, Get 2 Free",
    benjer_desc:
      'Ben & Jerry’s is offering you the chance to buy 2 cases of Ben & Jerry’s Pints and get 2 cases of Ben & Jerry’s 100ml FREE of charge',
    benjer_label: 'Great for increasing AOV',
    benjer_success_toast:
      "Thank you for signing up - your local Ben & Jerry's rep will be in contact within 48 hours to help you redeem this offer",
    benjer_opted_in_title: 'Your local rep will be in contact!',
    benjer_opted_in_desc:
      "You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    cokelemon_title: 'Free cases of Coke Lemon',
    cokelemon_description:
      'Coca Cola are offering two free cases of Coca-Cola Lemon & Coca-Cola Zero Sugar Lemon for you to add to your menu FREE of charge',
    cokelemon_label: 'Great for increasing AOV',
    cokelemon_success_toast:
      'Thanks for signing up - your product will be delivered in the next 10 days.',
    cokelemon_opted_in_title: 'Your FREE cases are coming!',
    cokelemon_opted_in_desc:
      "You can see all the <a class='font-bold text-blue-100' href='{link}'>next steps here</a>.",
    benjer100ml_title: "Ben & Jerry's 100ml: BOGOF",
    benjer100ml_description:
      "Ben & Jerry's is offering you the chance to buy 1 case of Ben & Jerry's 100ml and get 1 more case FREE of charge",
    benjer100ml_label: 'Great for increasing AOV and inclusion meal deals',
    benjer100ml_success_toast:
      "Thank you for registering interest in this offer - your local Ben & Jerry's rep will be in contact within 48 hours to help you redeem this offer",
    benjer100ml_opted_in_title: 'Your local rep will be in contact!',
    half_price_items: 'Half price:',
  },
}
