import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

// Based on this: https://github.com/iamkun/dayjs/issues/651
const ISO8601_OFFSET_FORMAT = /^(.*)([+-])(\d{2}):(\d{2})|(Z)$/

dayjs.create = function (date) {
  if (typeof date === 'string') {
    const match = date.match(ISO8601_OFFSET_FORMAT)
    if (match !== null) {
      if (match[0] === 'Z') {
        return dayjs(date, {
          utc: true,
          args: arguments,
        })
      }
      const [, dateTime, sign, tzHour, tzMinute] = match

      const h = parseInt(tzHour, 10)
      const m = parseInt(tzMinute, 10)
      const uOffset = h * 60 + m
      const offset = sign === '+' ? uOffset : -uOffset

      return dayjs(dateTime, {
        $offset: offset,
        args: arguments,
      })
    }
  }

  return dayjs(date, {
    args: arguments,
  })
}

const timeFormat = 'hh:mm:ss'

export const getValidOfferDate = (offerValidTo) => {
  return new Date(dayjs.create(offerValidTo).format('YYYY-MM-DD'))
}

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const isValidDifferenceInTime = (from, to) => {
  if (!from.length || !to.length) {
    return false
  }

  const fromTime = dayjs(from, timeFormat)
  const toTime = dayjs(to, timeFormat)

  return toTime.diff(fromTime) > 0
}

export const formatTime = (time) => {
  return time.slice(0, 5)
}

export const isNowBetween = (dayOfWeek, fromDate, toDate, fromTime, toTime) => {
  const now = dayjs()
  const formatedDays = [days[6], ...days.slice(0, 6)]

  if (formatedDays[now.day()] !== dayOfWeek) {
    return false
  }

  if (fromDate && now.isBefore(dayjs(fromDate))) {
    return false
  }

  if (toDate && now.isAfter(dayjs(toDate))) {
    return false
  }

  if (fromTime && now.isBefore(dayjs(fromTime, timeFormat))) {
    return false
  }

  if (toTime && now.isAfter(dayjs(toTime, timeFormat))) {
    return false
  }

  return true
}

export const isAfter = (start, end) => {
  return dayjs(start).isAfter(dayjs(end))
}
