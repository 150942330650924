export const TOGGLE_DELETE_MODAL = 'toggleDeleteModal'
export const TOGGLE_OPT_OUT_MODAL = 'toggleOptOutModal'
export const REMOVE_OFFER = 'removeOffer'
export const SET_OFFERS = 'setOffers'
export const SET_NOTIFICATION = 'setNotification'
export const TOGGLE_NOTIFICATION = 'toggleNotification'
export const SET_CAMPAIGNS = 'setCampaigns'
export const TOGGLE_SUBSCRIBE = 'toggleSubscribe'
export const SET_OFFER_INSIGHTS_DATA = 'setOfferInsightsData'
export const SET_FEATURE_TOGGLES = 'setFeatureToggles'
export const RESET_NOTIFICATION = 'resetNotification'
