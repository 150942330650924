<template>
  <div class="flex flex-col w-full">
    <div
      class="bg-white p-4 shadow-elevation-01 rounded-radius-c h-full relative"
    >
      <span
        class="pc-heading-s pr-12 inline-block"
        data-test-id="offer-card-title"
      >
        {{ offerTitle }}
      </span>
      <div class="my-2">
        <pc-tag
          v-if="showCustomTag"
          type="info"
          class="mr-2"
          data-test-id="campaign-tag"
        >
          {{ $t('campaign_text') }}
          <IconInfoCircle
            v-pc-tooltip="{
              content: $t('campaign_offer_info'),
              allowHTML: true,
            }"
            class="cursor-pointer ml-1"
          />
        </pc-tag>
        <pc-tag v-if="active" type="success" data-test-id="running-now-tag">
          {{ tagText }}
        </pc-tag>
      </div>
      <div v-if="offer.consumerSegment" class="flex items-center my-4 text-sm">
        <IconUsers v-if="allConsumerSegment" />
        <IconUserAdd v-if="newToRestaurantConsumerSegment" />
        <span class="ml-2" data-test-id="offer-card-consumer-segment">
          {{ consumerSegmentTitle }}
        </span>
      </div>
      <offer-card-items
        v-if="
          (isFreeItemQualifyingItemsOffer || isFreeItemMinSpendOffer) &&
          freeItems
        "
        :title="$t('free')"
        :items="freeItems"
        data-test-id="offer-card-free-items"
      >
        <template #image>
          <IconLogoPoints />
        </template>
      </offer-card-items>
      <offer-card-items
        v-if="isFreeItemQualifyingItemsOffer && qualifyingItems.length"
        :title="$t('with')"
        :items="qualifyingItems"
        data-test-id="offer-card-qualifying-items"
      >
        <template #image>
          <IconFoodReady />
        </template>
      </offer-card-items>
      <offer-card-items
        v-if="isItemLevelDiscountOffer && itemLevelDiscountItems.length"
        :title="$t('selected_items_title') + ':'"
        :items="itemLevelDiscountItems"
        data-test-id="offer-card-discounted-items"
      >
        <template #image>
          <IconFoodReady />
        </template>
      </offer-card-items>
      <offer-card-items
        v-if="isBogofOffer && bogofItems.length"
        :title="$t('buy')"
        :items="bogofItems"
        data-test-id="offer-card-bogof-free-items"
      >
        <template #image>
          <IconFoodReady />
        </template>
      </offer-card-items>
      <offer-card-items
        v-if="isBogofOffer && bogofItems.length"
        :title="$t('free')"
        :items="bogofItems"
        data-test-id="offer-card-bogof-qualifying-items"
      >
        <template #image>
          <IconLogoPoints />
        </template>
      </offer-card-items>
      <offer-card-items
        v-if="isBogofMixMatchOffer && bogofMixMatchItems.length"
        :title="$t('buy')"
        :items="bogofMixMatchItems"
        data-test-id="offer-card-bogof-mix-match-items"
      >
        <template #image>
          <IconFoodReady />
        </template>
      </offer-card-items>
      <offer-card-items
        v-if="isBogofMixMatchOffer && bogofMixMatchItems.length"
        :title="isHpOffer ? $t('half_price_items') : $t('free')"
        :items="bogofMixMatchItems"
      >
        <template #image>
          <IconLogoPoints />
        </template>
      </offer-card-items>
      <div class="mt-4 flex items-center text-sm" v-if="qualifyingValue">
        <template>
          <IconCash class="mr-2" />

          {{ $t('min_order') }}:
          <strong class="ml-1" data-test-id="offer-card-mov">
            {{ $n(qualifyingValue, 'currency') }}
          </strong>
        </template>
      </div>
      <div class="flex items-center text-sm mt-4">
        <template v-if="offerTime">
          <IconClock />
          <span class="ml-2" data-test-id="offer-card-time">
            {{ offerTime }}
          </span>
        </template>
      </div>
      <div class="flex items-center text-sm mt-4" v-if="showStartDate">
        <template>
          <IconCalendar class="mr-2" />
          <span
            data-test-id="offer-card-start-date"
            v-if="offer.runningPeriod.startDate"
          >
            {{ $t('starts_on') }}
            {{ $d(getValidOfferDate(offer.runningPeriod.startDate), 'short') }}
          </span>
        </template>
      </div>
      <div class="flex items-center text-sm mt-4">
        <template>
          <IconCalendar class="mr-2" />
          <span
            v-if="offer.runningPeriod.endDate"
            data-test-id="offer-card-end-date"
          >
            {{ $t('ends_on') }}
            {{ $d(getValidOfferDate(offer.runningPeriod.endDate), 'short') }}
          </span>
          <span v-else data-test-id="offer-card-end-when-deleted">
            {{ $t('ends_when_deleted') }}
          </span>
        </template>
      </div>
      <button
        class="absolute rounded-full top-0 right-0 p-4 focus:outline-none focus:shadow-outline-button bg-transparent border-transparent hover:bg-grey-20 active:bg-grey-25"
        v-if="canDeleteOffer"
        @click="deleteOffer"
        icon="trash"
        data-test-id="offer-card-delete-btn"
      >
        <IconTrash iconSize="m" />
      </button>

      <offer-insights
        class="mt-4"
        :insights="offer.insights"
        v-if="isOfferInsightsBannerEnabled"
        data-test-id="offer-insights-banner"
      />

      <pc-notification
        class="mt-4"
        v-if="showCampaignText"
        :data-test-id="offer.campaignId + '-note'"
        type="info"
      >
        {{ infoText }}
      </pc-notification>
    </div>
  </div>
</template>

<script>
import {
  IconUsers,
  IconUserAdd,
  IconLogoPoints,
  IconFoodReady,
  IconCash,
  IconClock,
  IconCalendar,
  IconTrash,
  IconInfoCircle,
} from '@justeattakeaway/pie-icons-vue'
import { PcTag, PcNotification } from '@je-pc/ui-components'
import dayjs from 'dayjs'
import OfferInsights from './OfferInsights.vue'
import OfferCardItems from './OfferCardItems.vue'
import { ConsumerSegment } from '../store/helpers'
import { formatTime, getValidOfferDate } from '../helpers/dateTimeHelper'
import {
  IS_OFFER_INSIGHTS_BANNER_ENABLED,
  ACTIVE_OFFERS,
  ENABLED_CAMPAIGNS,
} from '../store/getters.type'
import { CAMPAIGN_DAYS } from '../constants'
import { OfferTypes } from '../helpers/offerMap'
import { fromCoins } from '../helpers/money'
import { CAMPAIGN_CONFIGURATIONS } from '../helpers/campaignConfigurations'

export default {
  data() {
    return {
      CAMPAIGN_DAYS,
      OfferTypes,
    }
  },
  components: {
    PcTag,
    PcNotification,
    OfferInsights,
    OfferCardItems,
    IconUsers,
    IconUserAdd,
    IconLogoPoints,
    IconFoodReady,
    IconCash,
    IconClock,
    IconCalendar,
    IconTrash,
    IconInfoCircle,
  },
  props: {
    offer: {
      type: Object,
    },
    campaign: {
      type: Object,
    },
    day: {
      type: String,
    },
  },
  computed: {
    activeOffers() {
      return this.$store.getters[ACTIVE_OFFERS]
    },
    active() {
      return this.activeOffers.includes(this.offer)
    },
    offerTitle() {
      if (this.isPercentOffer) {
        return this.$t('percent_off', {
          value: this.offer.percent.discountPercent,
        })
      }
      if (this.isItemLevelDiscountOffer) {
        if (this.offer.itemLevelDiscount.discount.discountAmount) {
          return this.$t('value_off', {
            value: fromCoins(
              this.offer.itemLevelDiscount.discount.discountAmount
            ),
          })
        }
        return this.$t('percent_off_items', {
          value: this.offer.itemLevelDiscount.discount.discountPercentage,
        })
      }
      if (this.isFreeItemMinSpendOffer) {
        return this.$t('free_item_with_spend')
      }
      if (this.isFreeItemQualifyingItemsOffer) {
        return this.$t('free_item_with_item')
      }
      if (this.isBogofOffer) {
        return this.$t('bogof_title')
      }
      if (this.isBogofMixMatchOffer) {
        return this.offer.bogofMixMatch.discount.discountPercentage === 100
          ? this.$t('bogof_title')
          : this.$t('bogofhp_title')
      }
      return ''
    },
    tagText() {
      return this.isCampaignOfferDeleted
        ? this.$t('ending_tonight')
        : this.$t('active')
    },
    canDeleteOffer() {
      return (
        !this.offer.campaignId ||
        (this.offer.campaignId === this.campaign?.id &&
          !this.offer.runningPeriod.endDate) ||
        this.enabledCampaigns.find(
          (c) =>
            c.id === this.offer.campaignId &&
            c.type === 'MatchingByOfferTemplate'
        )
      )
    },
    isCampaignOfferDeleted() {
      return (
        this.campaign &&
        this.offer.campaignId === this.campaign.id &&
        this.CAMPAIGN_DAYS.includes(this.offer.dayOfWeek) &&
        !!this.offer.runningPeriod.endDate
      )
    },
    enabledCampaigns() {
      return this.$store.getters[ENABLED_CAMPAIGNS]
    },
    showCustomTag() {
      return (
        this.offer.campaignId &&
        this.enabledCampaigns.find(
          (c) =>
            c.id === this.offer.campaignId &&
            !c.exclusive &&
            c.type !== 'MatchingByOfferTemplate'
        )
      )
    },
    consumerSegmentTitle() {
      if (this.offer.consumerSegment == null) {
        return false
      }

      return this.$t(
        'consumer_segment_' +
          this.offer.consumerSegment.toString().toLowerCase()
      )
    },
    deleteOfferTitle() {
      if (this.offer.campaignId && this.campaign) {
        return this.$t(this.offer.campaignId.toLowerCase() + '_title', {
          day: this.offer.dayOfWeek,
        })
      }

      if (!this.offer.consumerSegment) {
        return this.offerTitle
      }

      return this.offerTitle + (' ' + this.consumerSegmentTitle).toLowerCase()
    },
    allConsumerSegment() {
      return this.offer.consumerSegment === ConsumerSegment.All
    },
    newToRestaurantConsumerSegment() {
      return this.offer.consumerSegment === ConsumerSegment.NewToRestaurant
    },
    qualifyingValue() {
      return this.offer.qualifyingCriteria?.minimumSpendValue
        ? fromCoins(this.offer.qualifyingCriteria?.minimumSpendValue).toFixed(2)
        : null
    },
    offerTime() {
      if (
        !this.offer.runningPeriod.endTime &&
        !this.offer.runningPeriod.startTime
      ) {
        return this.$t('all_day')
      }
      const from = this.offer.runningPeriod.startTime
        ? formatTime(this.offer.runningPeriod.startTime)
        : '00:00'
      const to = this.offer.runningPeriod.endTime
        ? formatTime(this.offer.runningPeriod.endTime)
        : '00:00'
      return `${from} - ${to}`
    },
    isOfferInsightsBannerEnabled() {
      return this.$store.getters[IS_OFFER_INSIGHTS_BANNER_ENABLED]
    },
    isPercentOffer() {
      return this.offer.offerType === OfferTypes.Percent
    },
    isItemLevelDiscountOffer() {
      return this.offer.offerType === OfferTypes.ItemLevelDiscount
    },
    isFreeItemMinSpendOffer() {
      return (
        this.offer.offerType === OfferTypes.FreeItem &&
        this.offer.qualifyingCriteria.minimumSpendValue
      )
    },
    isFreeItemQualifyingItemsOffer() {
      return (
        this.offer.offerType === OfferTypes.FreeItem &&
        this.offer.qualifyingCriteria.productSet
      )
    },
    isBogofOffer() {
      return this.offer.offerType === OfferTypes.Bogof
    },
    isBogofMixMatchOffer() {
      return this.offer.offerType === OfferTypes.BogofMixMatch
    },
    freeItems() {
      return this.offer.freeItem?.productSet?.products
    },
    qualifyingItems() {
      return [
        ...(this.offer.qualifyingCriteria?.productSet?.categories || []),
        ...(this.offer.qualifyingCriteria?.productSet?.products || []),
      ]
    },
    itemLevelDiscountItems() {
      return [
        ...(this.offer.itemLevelDiscount?.productSet?.categories || []),
        ...(this.offer.itemLevelDiscount?.productSet?.products || []),
      ]
    },
    bogofItems() {
      return [
        ...(this.offer.bogof?.productSet?.categories || []),
        ...(this.offer.bogof?.productSet?.products || []),
      ]
    },
    bogofMixMatchItems() {
      return [
        ...(this.offer.bogofMixMatch?.productSet?.categories || []),
        ...(this.offer.bogofMixMatch?.productSet?.products || []),
      ]
    },
    showStartDate() {
      return dayjs().isBefore(dayjs(this.offer.runningPeriod.startDate))
    },
    campaignConfiguration() {
      return this.campaign && CAMPAIGN_CONFIGURATIONS[this.campaign.id]
    },
    showCampaignText() {
      return (
        this.campaignConfiguration?.campaignInfo &&
        this.CAMPAIGN_DAYS.includes(this.day) &&
        this.isPercentOffer
      )
    },

    infoText() {
      return this.$t(this.offer.campaignId.toLowerCase() + '_info')
    },
    isHpOffer() {
      return (
        this.isBogofMixMatchOffer &&
        this.offer.bogofMixMatch.discount.discountPercentage === 50
      )
    },
  },
  methods: {
    deleteOffer() {
      const data = {
        offer: this.offer,
        title: this.deleteOfferTitle,
      }

      this.$emit('delete', data)
    },
    getValidOfferDate,
  },
}
</script>
