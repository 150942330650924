import Vue from 'vue'
import VueI18n from 'vue-i18n'
import uk from './uk'
import it from './it'
import es from './es'
import ie from './ie'
import au from './au'
import { TENANT } from '@/constants'

Vue.use(VueI18n)

const Locales = {
  uk: 'en-Gb',
  ie: 'en-Ie',
  au: 'en-Au',
  es: 'es-Es',
  it: 'it-It',
}

const messages = {
  [Locales.uk]: uk.messages,
  [Locales.ie]: ie.messages,
  [Locales.au]: au.messages,
  [Locales.es]: es.messages,
  [Locales.it]: it.messages,
}

const numberFormats = {
  [Locales.uk]: uk.numberFormats,
  [Locales.ie]: ie.numberFormats,
  [Locales.au]: au.numberFormats,
  [Locales.es]: es.numberFormats,
  [Locales.it]: it.numberFormats,
}

const dateTimeFormats = {
  [Locales.uk]: uk.dateTimeFormats,
  [Locales.ie]: ie.dateTimeFormats,
  [Locales.au]: au.dateTimeFormats,
  [Locales.es]: es.dateTimeFormats,
  [Locales.it]: it.dateTimeFormats,
}

const i18n = new VueI18n({
  locale: Locales[TENANT],
  messages,
  numberFormats,
  dateTimeFormats,
  fallbackLocale: Locales.uk,
})

export default i18n
