export default {
  numberFormats: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
  messages: {
    offers_title: 'Ofertas',
    offers_title_fmgc: 'Tus ofertas',
    setup_new_offer: 'Añadir oferta',
    active: 'Ejecutando',
    want_delete_offer: 'Eliminar oferta',
    are_you_sure:
      '¿Seguro que deseas eliminar esta oferta del <span>{offer}</span>?',
    delete: 'Eliminar',
    cancel: 'Cancelar',
    learn_more: 'Más información',
    register_interest: 'Guardar interés',
    register: 'Inscribirme',
    no_thanks: 'No, gracias',
    min_order: 'Orden mín.',
    all_day: 'Todo el día',
    no_active_offers: 'Ninguna oferta',
    when_will_offer_run: '¿Cuándo se ejecutará la oferta?',
    which_days: 'En estos días',
    monday: 'Lun',
    tuesday: 'Mar',
    wednesday: 'Mié',
    thursday: 'Jue',
    friday: 'Vi',
    saturday: 'Sáb',
    sunday: 'Dom',
    currencySymbol: '€',
    Sunday: 'Domingo',
    Monday: 'Lunes',
    Tuesday: 'Martes',
    Wednesday: 'Miércoles',
    Thursday: 'Jueves',
    Friday: 'Viernes',
    Saturday: 'Sábado',
    generic_error: 'Es necesario pedir algo para que puedas continuar.',
    problem_on_our_side:
      'Es un problema por nuestra parte, lo sentimos. Vuelve a intentarlo.',
    smth_went_wrong: 'Se ha producido un error',
    off: 'descuento',
    percent_off: '{value}% de descuento',
    delete_toast: '{value} eliminado',
    or_over: 'o más',
    offer_created:
      'Oferta de {value}% de descuento para todos los clientes añadida',
    save: 'Guardar',
    opt_in: 'Inscribirse',
    opt_out: 'Excluirse',
    consumer_segment_newtorestaurant: 'Solo nuevos clientes',
    consumer_segment_all: 'Todos los clientes',
    offer_availability: 'Disponibilidad de oferta',
    offer_target_question: '¿Deseas establecer un objetivo?',
    next: 'Siguiente',
    back: 'Volver',
    consumer_segment_newtorestaurant_desc:
      'Atrae a nuevos clientes que no han realizado pedidos en tu restaurante antes.',
    consumer_segment_all_desc:
      'Haz que lo vea la mayoría de personas y obtén más ventas.',
    newtorestaurant_offer_created:
      'Oferta de {value}% de descuento solo para nuevos clientes añadida',
    offer_insights_time_period_note:
      'Estos resultados se actualizan cada 24 horas.<br/><br/>Muestran los pedidos completados mientras tu oferta estaba activa.<br/><br/>Ten en cuenta que los resultados de la oferta no incluyen ningún ajuste o reembolso de pedidos.',
    offer_insights_time_period: 'Resultados de los últimos 7 días',
    no_offer_insights_yet:
      'Es demasiado pronto para ver cómo va esta oferta, vuelve a comprobarlo más tarde.',
    offer_insights_error:
      'Hay un problema al mostrar tus resultados, vuelve comprobarlo más tarde.',
    insights_sales: 'Ventas',
    insights_orders_count: 'Pedidos',
    insights_avg_order_value: 'Valor medio',
    what_hours_will_it_run: 'Elige la franja horaria para activar tu oferta',
    time_duration_day: 'Todo el día',
    time_duration_hours: 'Solo horas seleccionadas',
    how_long_will_the_offer_run: '¿Cuándo finalizará la oferta?',
    how_long_will_the_offer_run_description:
      'Puedes eliminar una oferta en cualquier momento desde la página principal de ofertas.',
    time_end_never: 'Hasta que la borre',
    time_end_selected: 'Durante un tiempo seleccionado',
    time_end_value_week: 'semana',
    time_end_value_weeks: 'semanas',
    time_duration_from: 'Desde',
    time_duration_to: 'Hasta',
    time_duration_dropdown_select: 'Seleziona',
    time_duration_from_validation: 'Elije una hora de inicio',
    time_duration_to_validation: 'Elije una hora de finalización',
    time_duration_difference_validation: 'Selecciona la duración correcta',
    time_duration_reserved_time_slots_note:
      'Ya tienes una oferta entre {timeSlots}. No puedes configurar una oferta en estas horas.',
    chose_offer_type: '¿Qué tipo de oferta te gustaría ejecutar?',
    percent_off_title: '% de descuento',
    percent_offer_desc: 'P. ej., 10 % de descuento al gastar 20 € o más.',
    free_item_title: 'Artículo gratuito',
    free_item_desc: 'P. ej., una bebida gratuita al pedir una comida.',
    offer_discount: 'Descuento de oferta',
    offer_discount_title: 'Establece el gasto apto y el % de descuento',
    qualifying_spend_title: 'Gasto mínimo',
    qualifying_spend_desc: 'Establece el gasto apto y el % de descuento',
    percent_discount_title: '% de descuento',
    percent_discount_desc:
      'Establece el importe de descuento que los clientes recibirán de su pedido',
    offer_type: 'Tipo de oferta',
    ends_on: 'Termina el',
    ends_when_deleted: 'Termina cuando se borre',
    make_sure: 'Asegúrate de haber hecho tu selección',
    target_validation_msg: 'Selecciona a quién deseas dirigirte',
    save_offer: 'Guardar oferta',
    days_validator:
      'Selecciona los días para ver las franjas horarias disponibles',
    offer_created_success: 'Nueva oferta creada con éxito',
    start: 'Empezar',
    end: 'Terminar',
    when_offer_start: '¿Cuándo comenzará la oferta?',
    immediately: 'Inmediatamente',
    on_set_date: 'En una fecha establecida',
    select_start_date: 'Seleccione la fecha de inicio',
    select_end_date: 'Seleccione la fecha de finalización',
    new_offer_type: 'Nuevas ofertas disponibles',
    new_offer_type_desc: 'Aumenta tus ventas con artículos gratis',
    how_get: '¿Cómo obtiene el cliente un artículo gratuito?',
    with_item_title: 'Al comprar otro artículo',
    with_item_desc:
      'Por ejemplo, obtienen una bebida gratis cuando piden una comida.',
    new: 'Nuevo',
    reach_title: 'Al gastar una cantidad mínima',
    reach_desc: 'Por ejemplo, obtienen un postre gratis cuando gastan 20 €.',
    offer_items: 'Artículos de la oferta',
    pick_requirements: 'Selecciona los artículos de la oferta',
    qualifying_items_title: 'Artículos de la oferta',
    qualifying_items_min_spend:
      'Establece el monto que el cliente debe gastar para obtener el artículo gratis.',
    qualifying_items_desc:
      'Selecciona los artículos que el cliente debe pedir para obtener el artículo gratuito.',
    free_item_picker_desc:
      'Selecciona el artículo (o artículos) gratuito que el cliente puede elegir.',
    add_items: 'Añadir artículos',
    select_items: 'Seleccionar artículos que cumplen los requisitos',
    select_free_items: 'Selecciona artículos gratuitos',
    select_all: 'Seleccionar todos los artículos',
    search: 'Buscar',
    add_tems_to_offer: 'Añade artículo(s) a la oferta',
    free_items: 'Artículos gratuitos',
    num_items: 'artículos | {count} artículo | {count} artículos',
    select_value_and_items: 'Ver el gasto necesario y los artículos gratuitos',
    percent_off_what: '¿% de descuento en qué?',
    selected_items_title: 'Artículos seleccionados',
    selected_items_desc: 'P. ej., 10 % en todas las pizzas de 30 cm.',
    total_order_title: 'Valor total del pedido',
    total_order_desc:
      'Por ejemplo, un 10 % de descuento en su pedido al gastar 20 €.',
    item_level_discount_title:
      'Selecciona los artículos con descuento y el % de descuento',
    discount_items_title: 'Artículos con descuento',
    discount_items_desc:
      'Selecciona los artículos en los que ofreces un descuento.',
    percent_discount_items:
      'Establece el importe de descuento que los clientes recibirán en estos artículos.',
    select_items_discount:
      'Selecciona artículos a los que realizar el descuento',
    free_item_with_item: 'Artículo gratuito con el artículo',
    free_item_with_spend: 'Artículo gratuito con gasto',
    free: 'Gratuito:',
    with: 'Con:',
    percent_off_items: '{value}% de descuento en artículos seleccionados',
    invalid_menu_message:
      'Algunos tipos de oferta no están disponibles actualmente debido a problemas con tu menú. Por favor, edita tu menú para solucionar este problema.',
    campaign_text: 'Campaña personalizada',
    campaign_offer_info:
      'Esta oferta se creó como parte de una campaña de toda la marca o de una campaña en la que te inscribiste previamente, por lo que no puede eliminarse hasta que la campaña finalice.',
    bogof_title: 'Compra uno y llévate otro gratis',
    bogofhp_title: 'Compre uno y llévese otro a mitad de precio',
    buy: 'Comprar:',
    ofertas_title: 'Ofertas de campeonato',
    ofertas_description:
      'Aumenta tu número de órdenes ofreciendo a tus clientes un 20% de descuento en pedidos iguales o superiores a 15€ durante los días de partido. Promocionaremos esta oferta para atraer clientes a tu restaurante. ¡Date prisa! Puedes inscribirte hasta el 6 de febrero de 2023.',
    ofertas_label: 'Es ideal para aumentar tus pedidos',
    ofertas_success_toast:
      'Te has registrado en la campaña Ofertas de campeonato',
    ofertas_opted_in_title: 'Ofertas de campeonato',
    ofertas_next_step: 'Gracias por inscribirte.',
    ofertas_legal_description:
      'Al hacer clic en "Inscribirme" confirmas que has leído y entendido la dinámica de la oferta, y que eres responsable sobre toda la información relacionada con los alimentos (incluidos los alérgenos) y los precios en tu menú.',
    ofertas_legal_title: 'Inscríbete a la campaña',
    terms_and_conditions: ' Términos y condiciones aplicados',
    benjerry_title: '🍦 GRATIS 2 cajas Ben & Jerry’s 🍦',
    benjerry_label: 'Haz crecer tus pedidos',
    benjerry_description:
      "Se acerca la temporada de mayor consumo de helado, una oportunidad para vender más postres y aumentar tu ticket medio, incluyendo helados en tu menú. Por esto, te regalamos 2 cajas de tarrinas de 100 ml de Ben&Jerry's. Haz clic a continuación y las recibirás en los próximos días.",
    benjerry_success_toast:
      'Te has registrado en la campaña GRATIS 2 cajas Ben & Jerry’s',
    benjerry_opted_in_title: '¡Hemos recibido tu confirmación!',
    benjerry_opted_in_description:
      'Después del 26 de febrero recibirás información sobre el envio de tus helados.',
    benjerry_legal_title: 'Inscríbete a la campaña',
    benjerry_legal_description:
      "Acepto incluir el helado de Ben & Jerry's a mi menú con el precio sugerido 3,50€/unidad. Al participar en esta promoción, acepto que mis datos sean compartidos con Unilever para la entrega de los productos y que Just Eat añada los productos a mi menú.",
    new_partner_title: '¿Quieres ganar 50€?',
    new_partner_desc:
      '¿Conoces un restaurante que aún no haga parte de Just Eat? Haz clic a continuación y recomienda un nuevo restaurante para que haga parte de Just Eat. Si éste se da de alta, te regalamos 50€ para materiales esenciales de tu negocio.',
    new_partner_opted_in_title: '¿Cómo recomendar un restaurante?',
    new_partner_opted_in_desc: '¿A qué esperas?',
    new_partner_success_toast:
      'Te has registrado en la campaña ¿Quieres ganar 50€?',
    value_off_title: 'Importe de descuento',
    value_off_desc:
      'P. ej., 2€ de descuento los combos de hamburguesas o 5€ de descuento en pedidos superiores a 20€.',
    value_off_what: '¿Sobre qué se aplica el importe de descuento?',
    value_selected_items_title: 'Artículos seleccionados',
    value_selected_items_desc:
      'P. ej., 5 € de descuento en todas las pizzas de 30 cm.',
    value_off_page_title: 'Seleccionar artículos y descuento',
    value_off_subtitle: 'Importe de descuento en €',
    value_off_info:
      'Establece el importe que se descontará a los clientes en artículos seleccionados.',
    value_discount_items_title: 'Artículo(s) con descuento',
    value_discount_items_desc:
      'Selecciona los artículos en los que los clientes recibirán un descuento.',
    value_off: '{value} € de descuento en artículos seleccionados',
    value_off_error:
      'Este importe de descuento supera el valor de algunos de los artículos seleccionados.',
    value_off_item_error:
      'El precio del artículo es inferior al importe de descuento',
    bogof_desc: 'P. ej., compra 1 pizza y llévate otra gratis.',
    bogo_items_desc:
      'Sobre los artículos que selecciones, tus clientes podrán elegir dos y llevarse el de menor precio gratis.',
    bogo_free_item: 'Artículo gratuito',
    bogo_desc:
      'Cuando el cliente elija dos de los artículos en oferta, los clientes podrán llevarse el artículo de menor precio completamente gratis.',
    free_item_info:
      'Los artículos que selecciones como gratuitos, serán los mismos artículos que se incluyen como requisito para la oferta.',
    items_picker_info_title:
      'Este tipo de oferta se activa solamente con los artículos que pertenecen a una misma categoría.',
    items_picker_info_desc:
      'Para activar la oferta Compra 1 y llévate 1 gratis en varias categorías, debes crear una oferta independiente para cada categoría.',
    overlapping_title: 'Ya tienes una oferta similar activa el {days}',
    overlapping_desc:
      'No puedes activar dos ofertas similares al mismo tiempo. Prueba con otro día u otra fecha de inicio.',
    half_price_items: 'Mitad de precio:',
  },
  dateTimeFormats: {},
}
